import './styles.css';

import AccountClassificationImage from './images/game-account-classification.png';
import NormalBalanceImage from './images/game-normal-balance.png';
import ProfitImage from './images/game-the-profit.png';
import BalancedEquationImage from './images/game-the-balanced-equation.png';
import Item from './item';

export const games = [
  {
    title: 'Account Classification',
    color: '#FDD0D4',
    image: AccountClassificationImage
  },
  {
    title: 'Normal Balance',
    color: 'rgba(30, 144, 255, 0.5)',
    image: NormalBalanceImage
  },
  {
    title: 'The Balanced Equation',
    color: '#F1C265',
    image: BalancedEquationImage
  },
  {
    title: 'The Profit Co',
    color: '#F7DAA3',
    image: ProfitImage
  }
];

export default function InstructorGames() {
  return (
    <ul className="instructor-games-demo">
      {games.map((game, index) => (
        <Item key={index} game={game} />
      ))}
    </ul>
  );
}
