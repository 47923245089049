
import DropArea from './dropArea';

import SignMinus from './images/icon-minus.svg';
import SignEquals from './images/icon-equals.svg';

import './styles.css';

export default function TransactionRecordsBody({
  options,
  value,
  onChangeItemDrop,
  onCoreItemDrop,
  onDropAreaChange,
  resetKey,
  incorrectDecisions,
  isItemsDisabled
}) {
  return (
    <div className="transaction-records-body">
      <div className="top-options-row">
        <h4>{options[0]?.name}</h4>
        <img src={SignMinus} alt="Minus" />
        <h4>{options[1]?.name}</h4>
        <img src={SignEquals} alt="Equals" />
        <h4>{options[2]?.name}</h4>
      </div>
      <div className="records-row">
        <DropArea 
          option={options[0]}
          value={value}
          onChangeItemDrop={onChangeItemDrop}
          onCoreItemDrop={onCoreItemDrop}
          onDropAreaChange={onDropAreaChange}
          resetKey={resetKey}
          incorrectDecisions={incorrectDecisions.filter(incorrectDecision => incorrectDecision.optionAccountItemId === options[0]?.accountItemId)}
          isItemsDisabled={isItemsDisabled}
        />
        <DropArea 
          option={options[1]}
          value={value}
          onChangeItemDrop={onChangeItemDrop}
          onCoreItemDrop={onCoreItemDrop}
          onDropAreaChange={onDropAreaChange}
          resetKey={resetKey}
          incorrectDecisions={incorrectDecisions.filter(incorrectDecision => incorrectDecision.optionAccountItemId === options[1]?.accountItemId)}
          isItemsDisabled={isItemsDisabled}
        />
        <DropArea 
          option={options[2]}
          value={value}
          onChangeItemDrop={onChangeItemDrop}
          onCoreItemDrop={onCoreItemDrop}
          onDropAreaChange={onDropAreaChange}
          resetKey={resetKey}
          incorrectDecisions={incorrectDecisions.filter(incorrectDecision => incorrectDecision.optionAccountItemId === options[2]?.accountItemId)}
          isItemsDisabled={isItemsDisabled}
        />
      </div>
    </div>
  );
}
