import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import './styles.css';
import InstructorLayout from '../UI/instructor/layout';

export default function Root() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isInstructorLayout, setIsInstructorLayout] = useState(false);

  useEffect(() => {
    if (location.pathname === '/') {
      navigateToLogin(navigate);
    }
    
    const onInstructorPage = location.pathname.includes('/instructor') && !location.pathname.includes('/onboarding');
    setIsInstructorLayout(onInstructorPage);
  }, [location, navigate]);

  return (
    <div className="Root">
      {
        isInstructorLayout ? (
          <InstructorLayout>
            <Outlet />
          </InstructorLayout>
        ) : (
          <>
            <Outlet />
          </>
        )
      }
    </div>
  );
}

function navigateToLogin(navigate) {
  navigate(`acc/login`);
}
