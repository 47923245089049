import { useState, useEffect, useRef } from 'react';
import Typed from 'typed.js';
import './styles.css';

export default function InstructorTransactions({
  transactions,
  selectedTransactionId,
  onSelectChange
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const textWrapperRef = useRef(null);
  const [typedText, setTypedText] = useState('');
  
  const handleSelectToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSelectChange = (selectTransaction) => {
    onSelectChange(selectTransaction);
    setIsExpanded(false);
  };

  useEffect(() => {
    const selectedTransaction = selectedTransactionId
      ? transactions.find(i => i.id === selectedTransactionId)?.description
      : transactions[0]?.description;

    if (selectedTransaction) {
      const typedInstance = new Typed(textWrapperRef.current, {
        strings: [selectedTransaction],
        typeSpeed: 20,
        smartBackspace: true,
        loop: false,
        showCursor: true,
        onComplete: (self) => {
          setTypedText(self.strings.join(''));
          if (typedInstance) {
            typedInstance.destroy();
          }
        }
      });

      return () => {
        if (typedInstance) {
          typedInstance.destroy();
        }
      };
    }
  }, [selectedTransactionId, transactions]);

  return (
    <div className="instructor-transactions-container">
      <h3>THE TRANSACTIONS</h3>
      <div className="transactions-select-wrapper">
        <div className="transactions-select-header" onClick={handleSelectToggle}>
          <span className="text-wrapper">
            <span ref={textWrapperRef}>{typedText}</span>
          </span>
          <span className="transactions-select-icon"></span>
        </div>
        <div className={`transactions-select-body ${isExpanded ? 'active' : ''}`}>
          <ul>
            {transactions.map((transaction, index) => (
              <li 
                key={index}
                onClick={() => handleSelectChange(transaction)}
              >
                {transaction.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
