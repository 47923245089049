import AuthService from './AuthService';
import { useEffect, useCallback } from 'react';

const useAuth = () => {
  const resetInactivityTimer = useCallback(() => {
    const logoutTime = Date.now() + 15 * 60 * 1000; // 15 minutes from now
    AuthService.setInactivityTime(logoutTime);
  }, []);

  useEffect(() => {
    const handleActivity = () => {
      resetInactivityTimer();
    };

    const checkInactivity = () => {
      const logoutTime = AuthService.getInactivityTime();
      if (logoutTime && Date.now() > logoutTime) {
        logout();
      }
    };

    const events = ['click', 'mousemove', 'keypress', 'scroll'];
    events.forEach(event => window.addEventListener(event, handleActivity));

    const syncLogoutTime = (event) => {
      if (event.key === 'inactivityTime') {
        checkInactivity();
      }
    };

    window.addEventListener('storage', syncLogoutTime);
    const inactivityCheckInterval = setInterval(checkInactivity, 1000);

    resetInactivityTimer();

    return () => {
      events.forEach(event => window.removeEventListener(event, handleActivity));
      window.removeEventListener('storage', syncLogoutTime);
      clearInterval(inactivityCheckInterval);
    };
  }, [resetInactivityTimer]);

  const login = (token) => {
    AuthService.setToken(token);
    AuthService.setSoundStatus('enabled');

    resetInactivityTimer();
  };

  const saveUser = (userData) => {
    AuthService.setUser(userData);
  };

  const getUser = () => {
    return AuthService.getUser();
  };

  const getSoundStatus = () => {
    return AuthService.getSoundStatus();
  };
  
  const setSoundStatus = (status) => {
    return AuthService.setSoundStatus(status);
  };

  const logout = () => {
    AuthService.clearToken();
    AuthService.clearUser();
    AuthService.clearSoundStatus();
    AuthService.clearCurrentGameId();
    AuthService.clearCurrentScenarioId();
    AuthService.clearInactivityTime();
    AuthService.clearGuidedPlay();

    window.location.href = '/acc/login';
  };

  const isUserAuthenticated = () => {
    return AuthService.getToken() && AuthService.getUser();
  };

  const isInstructor = () => {
    return AuthService.getUser() ? AuthService.getUser().is_instructor : false;
  };
  
  const isStudent = () => {
    return AuthService.getUser() ? AuthService.getUser().is_student : false;
  };

  const saveCurrentGameId = (id) => {
    AuthService.setCurrentGameId(id);
  };

  const getCurrentGameId = () => {
    return Number(AuthService.getCurrentGameId());
  };

  const saveCurrentScenarioId = (id) => {
    AuthService.setCurrentScenarioId(id);
  };

  const getCurrentScenarioId = () => {
    return Number(AuthService.getCurrentScenarioId());
  };
  
  const saveGuidedPlay = (status) => {
    AuthService.setGuidedPlay(status);
  };

  const getGuidedPlay = () => {
    return AuthService.getGuidedPlay();
  };

  return { 
    login,
    saveUser,
    logout,
    isUserAuthenticated,
    getUser,
    isInstructor,
    isStudent,
    setSoundStatus,
    getSoundStatus,
    saveCurrentGameId,
    getCurrentGameId,
    saveCurrentScenarioId,
    getCurrentScenarioId,
    saveGuidedPlay,
    getGuidedPlay
  };
};

export default useAuth;
