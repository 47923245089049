export const popupMessagesTop = [
  {
    name: 'Bailey',
    text: 'Welcome! I\'m Bailey, and this is Benny!',
    alignment: 'left'
  }
];

export const popupMessagesBottom = [
  {
    name: 'Benny',
    text: 'Here at Ledger Legends, we\'re all about helping small businesses navigate the twists and turns of their financial journey.',
    alignment: 'right'
  }
];
