import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';
import Header from '../../components/UI/game/header';

import { 
  gamesQuery
} from './api';

import GamesDashboard from '../UI/gamesDashboard';

import './styles.css';

export default function Games() {
  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  const { isInstructor } = useAuth();
  const role = isInstructor() ? 'instructor' : 'student';

  return (
    <div className="games-page-wrapper">
      <Header />
      <div className="games-content-wrapper">
        <div className="container games-container">
          <h1>Business Games</h1>
          {
            games && 
              <GamesDashboard 
                games={games}
                role={role}
              />
          }
        </div>
      </div>
    </div>
  );
}
