import { Link, useLocation } from 'react-router-dom';

import './styles.css';

import { getPathSegments } from '../../../../utils';

export default function InstructorSubPagesLayout({
  children,
  navigation,
  contentClassName,
  onPrevButtonClick,
  onNextButtonClick,
  currentPage = 1,
  pagesQuantity = 1
}) {
  const location = useLocation();

  return (
    <div className="instructor-subpages-layout">
      <div className="instructor-subpages-leftbar">
        <ul>
          {navigation.map((navigationItem, index) => {

            const isActiveLink = (location.pathname === navigationItem.url) // highlight the exact same link as we are on
              || (getPathSegments(location.pathname).slice(0, 3).join('/') === getPathSegments(navigationItem.url).slice(0, 3).join('/')) // highlight links with the same 3 levels of depth
              || (location.pathname === '/instructor/dashboard' && navigationItem.url === '/instructor/welcome/welcome-to-accounting-games') // highlight first Welcome link on Dashboard page opened
              || (location.pathname === '/instructor/insights/' && navigationItem.url === '/instructor/insights/overall/leaderboard'); // highlight first insights link on Insights page opened

            return (
              <li key={index} className={`${navigationItem.children ? 'has-children' : ''} ${isActiveLink ? 'contains-active-link' : ''}`}>
                <Link
                  to={navigationItem.url}
                  className={`${isActiveLink ? 'active' : ''}`}
                  title={navigationItem.title}
                >
                  {navigationItem.title}
                </Link>
                {
                  navigationItem.children &&
                    <ul className="instructor-subpages-children">
                      {navigationItem.children.map((navigationChild, index) => {
                        const isActiveLink = location.pathname === navigationChild.url
                        || (getPathSegments(location.pathname).slice(0, 4).join('/') === getPathSegments(navigationChild.url).slice(0, 4).join('/')) // highlight links with the same 4 levels of depth
                        || (location.pathname === '/instructor/insights/' && navigationChild.url === '/instructor/insights/overall/leaderboard'); // highlight first insights overall link on Insights page opened;
                        return (
                          <li key={index}>
                            <Link
                              to={navigationChild.url}
                              className={`${isActiveLink ? 'active' : ''}`}
                              title={navigationChild.title}
                            >
                              {navigationChild.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                }
              </li>
            );
          })}
        </ul>
      </div>
      <div className={`instructor-subpages-content ${contentClassName}`}>
        <div className="content-wrapper">
          {children}
        </div>

        {(onPrevButtonClick || onNextButtonClick) && (
          <div className="instructor-subpages-bottom-navigation">
            {onPrevButtonClick && (
              <button className="subpages-button-prev" onClick={onPrevButtonClick}>
                Previous
              </button>
            )}
            <span className="instructor-subpages-pagination">{currentPage} / {pagesQuantity}</span>
            {onNextButtonClick && (
              <button className="button-blue subpages-button-next" onClick={onNextButtonClick}>
                Next
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
