
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { 
  gamesQuery,
  getScenarioLevels,
  getCourseMembers,
  getClassificationScores,
  getNormalScores,
  getProfitScores,
  getBalancedEquationScores
} from '../api';

import './styles.css';

import { Leaderboard } from '../../../UI/instructor/leaderboard';

import { sortAndSelectTop } from '../../../../utils';
import InstructorSubPagesLayout from '../../../UI/instructor/subPagesLayout';

import { navigation } from '../data';

export default function InstructorInsightsLeaderboard() {
  const navigate = useNavigate();

  const [scenarioId, setScenarioId] = useState(0);
  const [courseId, setCourseId] = useState(0);

  const [selectedLevel, setSelectedLevel] = useState(0);
  const [selectedGame, setSelectedGame] = useState(null);

  const [sort, setSort] = useState('desc');
  const [isRandomOrder, setIsRandomOrder] = useState(true);

  const [data, setData] = useState(null);

  const [isUpdateResultsButtonClicked, setIsUpdateResultsButtonClicked] = useState(false);

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  useEffect(() => {
    if (games) {
      let game = games.find(game => game.game_type.includes('Account Classification'));
  
      if (!game) {
        game = games[0];
      }
  
      if (game) {
        setScenarioId(game.scenario);
        setCourseId(game.course);
        setSelectedGame({ id: game.id, type: game.game_type });
      }
    }
  }, [games]);  

  const { data: scenarioLevels, refetch: refetchScenarioLevels, isFetching: isScenarioLevelsFetching } = useQuery({
    queryKey: ['scenarioLevels'],
    queryFn: async () => getScenarioLevels(scenarioId),
    enabled: !!scenarioId,
    retry: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (scenarioLevels?.length > 0) {
      setSelectedLevel(scenarioLevels[0].level);
    }
  }, [scenarioLevels]);

  const { data: classificationScores, refetch: refetchClassificationScores, isFetching: isClassificationScoresFetching } = useQuery({
    queryKey: ['classificationScores'],
    queryFn: async () => getClassificationScores(selectedGame?.id),
    enabled: false,
    retry: false,
  });

  const { data: normalScores, refetch: refetchNormalScores, isFetching: isNormalScoresFetching } = useQuery({
    queryKey: ['normalScores'],
    queryFn: async () => getNormalScores(selectedGame?.id),
    enabled: false,
    retry: false,
  });
  
  const { data: profitScores, refetch: refetchProfitScores, isFetching: isProfitScoresFetching } = useQuery({
    queryKey: ['profitScores'],
    queryFn: async () => getProfitScores(selectedGame?.id),
    enabled: false,
    retry: false,
  });
  
  const { data: balancedEquationScores, refetch: refetchBalancedEquationScores, isFetching: isBalancedEquationScoresFetching } = useQuery({
    queryKey: ['balancedEquationScores'],
    queryFn: async () => getBalancedEquationScores(selectedGame?.id),
    enabled: false,
    retry: false,
  });
  
  const { data: courseMembers } = useQuery({
    queryKey: ['courseMembers'],
    queryFn: async () => getCourseMembers(courseId),
    enabled: !!courseId,
    retry: false,
  });

  const isLoading = isClassificationScoresFetching
    || isNormalScoresFetching
    || isProfitScoresFetching
    || isBalancedEquationScoresFetching
    || isScenarioLevelsFetching;

  const handleLevelChange = (selectedItem) => {
    setSelectedLevel(selectedItem.id);
    setSort('desc');
    setIsRandomOrder(true);
  };

  const handleGameChange = (selectedItem) => {
    setSort('desc');
    setIsRandomOrder(true);

    const game = games.find(game => game.id === selectedItem.id);

    if(game) {
      setSelectedGame({id: selectedItem.id, type: selectedItem.title});
      setScenarioId(game.scenario);
      setSelectedLevel(0);
    }
  };

  const refetchGameData = () => {
    if(selectedGame.type.includes('Account Classification')) {
      refetchClassificationScores();
    } else if(selectedGame.type.includes('Normal Balance')) {
      refetchNormalScores();
    } else if(selectedGame.type.includes('The Profit')) {
      refetchProfitScores();
    } else if(selectedGame.type.includes('Balanced Equation')) {
      refetchBalancedEquationScores();
    }
  }

  useEffect(() => {
    if (selectedGame) {
      refetchGameData();
    }
  }, [selectedGame]); 

  useEffect(() => {
    if (scenarioId) {
      refetchScenarioLevels();
    }
  }, [scenarioId]); 
  
  const selectLevelItems = scenarioLevels?.sort((a, b) => a.level - b.level).map(level => ({
    id: level.level,
    title: `Level ${level.level}`,
  })) || [{id: 0, title: 'Level'}];
  
  const selectGameItems = games?.map(game => ({
    id: game.id,
    title: game.game_type,
  })) || [{id: 0, title: 'Game'}];
    
  const handleSortClick = () => {
    const changedSort = sort === 'desc' 
      ? isRandomOrder
        ? 'desc'
        : 'asc'
      : 'desc';
    setIsRandomOrder(false);
    setSort(changedSort);
  };
  
  const handleUpdateResultsClick = () => {
    setIsUpdateResultsButtonClicked(true);
    setSort('desc');
    setIsRandomOrder(true);
    
    refetchGameData();
  };

  const formatLeaderboardData = () => {    
    let wholeLeaderboardData = [];
    let scores;

    if(selectedGame.type.includes('Account Classification')) {
      scores = classificationScores;
    } else if(selectedGame.type.includes('Normal Balance')) {
      scores = normalScores;
    } else if(selectedGame.type.includes('The Profit')) {
      scores = profitScores;
    } else if(selectedGame.type.includes('Balanced Equation')) {
      scores = balancedEquationScores;
    }
    
    wholeLeaderboardData = courseMembers.map(courseMember => {
      const itemScore = scores.find(score => score.user === courseMember.user_id && score.level === selectedLevel);
      const score = itemScore
        ? itemScore.total_points
        : 0;
      
      return {
        name: courseMember.name,
        score,
        formattedScore: score
      }
    });

    const sortedLeaderboardData = sortAndSelectTop(wholeLeaderboardData, sort, isRandomOrder);

    setData(sortedLeaderboardData);
  }

  useEffect(() => {
    if(
      selectedLevel 
      && selectedGame 
      && courseMembers 
      && ((selectedGame.type.includes('Account Classification') && classificationScores) 
        || (selectedGame.type.includes('Normal Balance') && normalScores)
        || (selectedGame.type.includes('The Profit') && profitScores)
        || (selectedGame.type.includes('Balanced Equation') && balancedEquationScores))
      && !isLoading
    ) {
      formatLeaderboardData();
    }
  }, [
    selectedLevel,
    selectedGame,
    courseMembers,
    classificationScores,
    normalScores,
    profitScores,
    balancedEquationScores,
    isLoading,
    sort,
    isRandomOrder
  ]);
  
  const maxScore = data ? data.reduce((max, item) => (item.score > max ? item.score : max), -Infinity) : 0;

  const handleNextButtonClick = () => {
    navigate('/instructor/insights/overall/accuracy');
  }

  return (
    <InstructorSubPagesLayout
      navigation={navigation}
      contentClassName="insights-content-container"
      onNextButtonClick={handleNextButtonClick}
    >
      <h1>Insights | Overall</h1>
      <h2>Leaderboard</h2>
      <p>Points are driven by how quickly students make correct decisions.</p>
      <div className="insights-container">
        <Leaderboard
          title="Leaderboard"
          data={data}
          maxScore={maxScore}
          isUpdateResultsButtonClicked={isUpdateResultsButtonClicked}
          onUpdateResultsClick={handleUpdateResultsClick}
          selectLevelItems={selectLevelItems}
          selectedLevel={selectedLevel}
          onLevelSelectChange={handleLevelChange}
          selectGameItems={selectGameItems}
          selectedGame={selectedGame}
          onGameSelectChange={handleGameChange}
          isLoading={isLoading}
          sort={sort}
          onSortClick={handleSortClick}
        />
      </div>
    </InstructorSubPagesLayout>
  );
}
