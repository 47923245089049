
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import useAuth from '../../../auth/useAuth';

import { updateGameIsActive } from './api';

import Modal from '../modal';

import './styles.css';

export default function Item({
  gameTemplate,
  relatedGame, 
  role
}) {
  const { 
    saveCurrentGameId,
    saveCurrentScenarioId,
    saveGuidedPlay
  } = useAuth();

  const splitType = relatedGame 
    ? relatedGame.game_type.split('|')
    : gameTemplate.type.split('|');

  const [gameStatus, setGameStatus] = useState(relatedGame ? relatedGame.is_active : false);
  
  const buttonStatus = gameStatus ? 'enabled' : 'disabled';

  const updateGameIsActiveMutation = useMutation({
    mutationFn: (data) => updateGameIsActive(data),
    onSuccess(result) {
      setGameStatus(result.is_active);
    },
    onError: (error) => {
      console.log('Error: ', error);
    }
  });

  const handleButtonStatusClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if(role === 'instructor') {
      updateGameIsActiveMutation.mutate({
        id: relatedGame.id,
        status: !gameStatus
      });
    }
  }
  
  const [isModalShown, setIsModalShown] = useState(false);

  const handleModalButtonClick = (event) => {
    event.stopPropagation();
    setIsModalShown(false);
  }

  const modalContent = <div className="modal-center vertical">
    <div><span>Game is disabled</span></div>
    <button className="button-navi" onClick={(event) => handleModalButtonClick(event)}>Ok</button>
  </div>

  const handlePlayButtonClick = (guided) => {
    saveGuidedPlay(guided);

    saveCurrentGameId(relatedGame.id);
    saveCurrentScenarioId(relatedGame.scenario);
    window.open('/acc/transition', '_blank');
  }
  
  const handleGameItemClick = () => {
    if(!gameStatus) {
      setIsModalShown(true);
    }
  }

  return (
    <div
      className={`game-item ${!relatedGame ? 'inactive' : ''}`}
      style={{background: gameTemplate.color}}
      onClick={handleGameItemClick}
    >
      {isModalShown && (
        <Modal
          content={modalContent}
        />
      )}
      <div className="game-item-header">
        <img src={gameTemplate.image} alt={gameTemplate.type}/>
        {
          relatedGame && 
            <button
              className={`game-status-button ${buttonStatus}`}
              onClick={(event) => handleButtonStatusClick(event)}
            >
              {buttonStatus.charAt(0).toUpperCase() + buttonStatus.slice(1)}
            </button>
        }
      </div>
      <div className="game-item-body">
        <h3>
          <span>{splitType[0]}</span>
        </h3>
        {
          gameStatus &&
            <div className="game-buttons-row">
              <button className="button-blue" onClick={() => handlePlayButtonClick(false)}>Quick Start</button>
              <button className="button-blue" onClick={() => handlePlayButtonClick(true)}>Guided Play</button>
            </div>
        }
      </div>
    </div>
  );
}
