const authService = {
  setToken: (value) => {
    localStorage.setItem('token', value);
  },
  
  getToken: () => {
    return localStorage.getItem('token');
  },
  
  clearToken: () => {
    localStorage.removeItem('token');
  },
  
  setUser: (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
  },
  
  getUser: () => {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  },
  
  clearUser: () => {
    localStorage.removeItem('user');
  },
  
  setSoundStatus: (value) => {
    localStorage.setItem('sound', value);
  },

  getSoundStatus: () => {
    return localStorage.getItem('sound');
  },
  
  clearSoundStatus: () => {
    localStorage.removeItem('sound');
  },
  
  setCurrentGameId: (value) => {
    localStorage.setItem('gameId', value);
  },

  getCurrentGameId: () => {
    return localStorage.getItem('gameId');
  },
  
  clearCurrentGameId: () => {
    localStorage.removeItem('gameId');
  },
  
  setCurrentScenarioId: (value) => {
    localStorage.setItem('scenarioId', value);
  },

  getCurrentScenarioId: () => {
    return localStorage.getItem('scenarioId');
  },
  
  clearCurrentScenarioId: () => {
    localStorage.removeItem('scenarioId');
  },

  setInactivityTime: (value) => {
    localStorage.setItem('inactivityTime', value);
  },

  getInactivityTime: () => {
    return localStorage.getItem('inactivityTime');
  },
  
  clearInactivityTime: () => {
    localStorage.removeItem('inactivityTime');
  },
  
  setGuidedPlay: (value) => {
    localStorage.setItem('guidedPlay', JSON.stringify(value));
  },

  getGuidedPlay: () => {
    const value = localStorage.getItem('guidedPlay');
    return value ? JSON.parse(value) : true;
  },
  
  clearGuidedPlay: () => {
    localStorage.removeItem('guidedPlay');
  },
};

export default authService;
