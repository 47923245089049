import './styles.css';

export default function Item({ game }) {
  return (
    <div
      className="game-item"
      style={{background: game.color}}
    >
      <div className="game-item-header">
        <img src={game.image} alt={game.type}/>
      </div>
      <div className="game-item-body">
        <h3>
          <span>{game.title}</span>
        </h3>
      </div>
    </div>
  );
}
