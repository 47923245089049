import axiosClient from '../../api/axiosClient.js';

async function fetchGamesPage(url, allGames = []) {
  const response = await axiosClient.get(url);
  const games = [...allGames, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesPage(response.data.next, games);
  } else {
    return games;
  }
}

async function getGames() {
  return fetchGamesPage(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false
  };
};