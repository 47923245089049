import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';
import Modal from '../UI/introGameLayout/modal';

import BackgroundImage from './images/game-background.jpg';

import { 
  getGamesSessions,
  scenarioLevelsQuery,
  makeTransactionDecision,
  getTransactionDecisions
} from './api';

import { 
  popupMessagesGameStart,
  popupMessagesGameEnd,
  transactionsAnswerOptions
} from './data';

import { calculateTimeInSeconds } from '../../utils';

import PopupMessages from '../UI/introGameLayout/popupMessages';
import TransactionsDecisions from '../UI/theProfit/transactionsDecisions';

import IncomeStatement from '../UI/theProfit/incomeStatement';
import Avatar from '../UI/introGameLayout/avatar';

import './styles.css';

export default function TheProfit() {
  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transactionToShow = Number(searchParams.get('transaction'));
  const transactionDecisionToShowStatus = searchParams.get('status');
  const selectedAnswerOptionToShow = searchParams.get('answer');

  const { getUser, getCurrentScenarioId, getCurrentGameId } = useAuth();

  const user = getUser();
  const username = user?.alias_name 
    ? user?.alias_name 
    : user?.first_name 
      ? user?.first_name
      : user?.username
        ? user?.username 
        : '';
  
  const currentGameId = getCurrentGameId();

  const modifiedPopupMessagesGameEnd = popupMessagesGameEnd.map(message => ({
    ...message,
    text: message.text.replace('{name}', username)
  }));

  const handleStartPopupMessagesButtonClick = () => {
    setIsPopupMessagesVisible(false);
    setIsFooterVisible(true);
    setIsOnGameAvatarVisible(true);
    setIsTransactionsVisible(true);
    setIsIncomeStatementVisible(true);

    setIsPlaying(true);
  };

  const handleEndPopupMessagesButtonClick = () => {
    navigate('/the-profit/debrief');
  };


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

  const [isPopupMessagesVisible, setIsPopupMessagesVisible] = useState(false);
  const [popupMessages, setPopupMessages] = useState(popupMessagesGameStart);
  const [popupMessagesButtonText, setPopupMessagesButtonText] = useState('Let\'s go');
  const [popupMessagesButtonHandler, setPopupMessagesButtonHandler] = useState(() => handleStartPopupMessagesButtonClick);

  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isOnGameAvatarVisible, setIsOnGameAvatarVisible] = useState(false);
  const [isTransactionsVisible, setIsTransactionsVisible] = useState(false);
  const [isIncomeStatementVisible, setIsIncomeStatementVisible] = useState(false);
  const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [isExplanationVisible, setIsExplanationVisible] = useState(false);
  const [explanationContent, setExplanationContent] = useState(null);
  const [correctlyAnsweredQuestions, setCorrectlyAnsweredQuestions] = useState([]);
  const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
  const [isPrevButtonVisible, setIsPrevButtonVisible] = useState(false);

  const [revenuesTotal, setRevenuesTotal] = useState(0);
  const [expensesTotal, setExpensesTotal] = useState(0);
  
  const [transactions, setTransactions] = useState([]);
  const [activeTransaction, setActiveTransaction] = useState(0);
  const scenarioLevelsResponse = useQuery(scenarioLevelsQuery(getCurrentScenarioId())) || {};

  const { data: transactionDecisionsResponse, isSuccess: isTransactionDecisionsSuccess } = useQuery({
    queryKey: ['transactionDecisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getTransactionDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id
  });

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessions'],
    queryFn: getGamesSessions,
  });

  useEffect(() => {
    if (gamesSessions && !gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
      navigate('/the-profit/tutorial');
    }
  }, [gamesSessions, navigate, user?.id, currentGameId]);

  useEffect(() => {
    const scenarioLevels = scenarioLevelsResponse.data || [];
  
    if (scenarioLevels.length && isTransactionDecisionsSuccess) {
      
      const scenarioLevel = scenarioLevels.find(level => level.level === levelNumber);
      if(scenarioLevel) {
        const sortedTransactions = scenarioLevel.transactions.sort((a, b) => a.order - b.order);
        setTransactions(sortedTransactions);
    
        const transactionDecisions = transactionDecisionsResponse || [];
        
        let transactionNotAnsweredCorrectly = 0;
        const isAnyQuestionAnsweredCorrectly = transactionDecisions.some(decision => decision.is_correct);
        
        if(isAnyQuestionAnsweredCorrectly || transactionToShow) {
          setIsBackgroundBlurred(true);
          setIsTransactionsVisible(true);
          setIsIncomeStatementVisible(true);
          setIsOnGameAvatarVisible(true);
          setIsFooterVisible(true);
          
          if(transactionToShow) {
            setAnswerStatus(transactionDecisionToShowStatus);
            setActiveTransaction(transactionToShow - 1);
            setSelectedAnswerOption(selectedAnswerOptionToShow);
            setIsExplanationVisible(true);
          } else {
            setIsPlaying(true);
          }

        } else {
          setIsModalVisible(true);

          setTimeout(() => {
            if(!correctlyAnsweredQuestions.length) {
              setIsModalVisible(false);
              setIsBackgroundBlurred(true);
              setIsPopupMessagesVisible(true);
            }
          }, 5000);
        }
    
        for (const transaction of sortedTransactions) {
          const relatedCorrectDecistion = transactionDecisions.find(decision => decision.question === transaction.id && decision.is_correct);
    
          if (!relatedCorrectDecistion) {
            transactionNotAnsweredCorrectly = transaction.order;
            break;
          }

          // Check if the transaction is already in correctlyAnsweredQuestions
          const isTransactionAdded = correctlyAnsweredQuestions.some(item => item.id === transaction.id);
    
          if (!isTransactionAdded) {
            setTotalScore(prevTotalScore => prevTotalScore + relatedCorrectDecistion.score_points);
            if (relatedCorrectDecistion.time_elapsed) {
              const timeInSeconds = calculateTimeInSeconds(relatedCorrectDecistion.time_elapsed);
              setTime(prevTime => prevTime + timeInSeconds);
              setItemTimeStart(prevTime => prevTime + timeInSeconds);
            }
            if(relatedCorrectDecistion.selected_option === 'Increase Profits') {
              setRevenuesTotal(prevRevenuesTotal => prevRevenuesTotal + relatedCorrectDecistion.value);
            } else if(relatedCorrectDecistion.selected_option === 'Decrease Profits') {
              setExpensesTotal(prevExpensesTotal => prevExpensesTotal - relatedCorrectDecistion.value);
            }
            setCorrectlyAnsweredQuestions(prevCorrectlyAnsweredQuestions => [
              ...prevCorrectlyAnsweredQuestions,
              { id: transaction.id, answer: relatedCorrectDecistion.selected_option, explanation: relatedCorrectDecistion.explanation }
            ]);
          }
        }
    
        if (transactionNotAnsweredCorrectly > 1) {
          setActiveTransaction(transactionNotAnsweredCorrectly - 1);
        }
      }
    }
  }, [levelNumber, scenarioLevelsResponse.data, isTransactionDecisionsSuccess, transactionDecisionsResponse]);

  useEffect(() => {
    if(correctlyAnsweredQuestions && transactionToShow && transactions) {
      const relatedCorrectlyAnsweredQuestion = correctlyAnsweredQuestions.find(correctlyAnsweredQuestion => correctlyAnsweredQuestion.id === transactions[activeTransaction]?.id);
      if(relatedCorrectlyAnsweredQuestion && relatedCorrectlyAnsweredQuestion.explanation) {
        setExplanationContent(relatedCorrectlyAnsweredQuestion.explanation);
      }
    }
  }, [correctlyAnsweredQuestions, transactionToShow, transactions, activeTransaction]);
  
  const handleAnswerOptionButtonClick = (option) => {
    setSelectedAnswerOption(option);
    setAnswerStatus(null);
    setIsExplanationVisible(false);
    
    const questionId = transactions[activeTransaction]?.id;
    const timeElapsed = time - itemTimeStart;
    decisionMutation.mutate({
      game: currentGameId,
      question: questionId,
      selectedOption: option,
      timeElapsed
    }, {
      onSuccess: (result) => {
        setIsExplanationVisible(true);
        setExplanationContent(result.explanation);
        if(result.is_correct) {
          setIsNextButtonVisible(true);
          
          if(activeTransaction >= 1) {
            setIsPrevButtonVisible(true);
          }

          setAnswerStatus('correct');
          setCorrectlyAnsweredQuestions([...correctlyAnsweredQuestions, {id: questionId, answer: option}]);
          setTotalScore(prevTotalScore => prevTotalScore + result.score_points);
          setShouldResetItemTimeStart(true);

          if(option === 'Increase Profits') {
            setRevenuesTotal(prevRevenuesTotal => prevRevenuesTotal + result.value);
          } else if(option === 'Decrease Profits') {
            setExpensesTotal(prevExpensesTotal => prevExpensesTotal - result.value);
          }
        } else {
          setAnswerStatus('incorrect');
        }
      },
      onError: (error) => {
        console.log('Error: ', error);
      }
    });
  };

  const handleNextButtonClick = () => {
    if(!correctlyAnsweredQuestions.some(correctlyAnsweredQuestion => correctlyAnsweredQuestion.id === transactions[activeTransaction + 1]?.id)) {
      console.log('here we should set prev button visibility to false');
      setIsNextButtonVisible(false);
      setIsPrevButtonVisible(false);

      if(shouldResetItemTimeStart) {
        setItemTimeStart(time);
        setShouldResetItemTimeStart(false);
      }
    }

    if((activeTransaction + 1) < transactions.length) {
      setActiveTransaction(prevActiveTransaction => prevActiveTransaction + 1);
    } else {
      setIsPlaying(false);
      setIsFooterVisible(false);
      setIsTransactionsVisible(false);
      setIsOnGameAvatarVisible(false);
      setPopupMessages(modifiedPopupMessagesGameEnd);
      setIsPopupMessagesVisible(true);
      setPopupMessagesButtonText('Next');
      setPopupMessagesButtonHandler(() => handleEndPopupMessagesButtonClick);
    }

    setIsExplanationVisible(false);
    setSelectedAnswerOption(null);
    setAnswerStatus(null);
  };

  const handlePrevButtonClick = () => {
    if(activeTransaction <= 1) {
      setIsPrevButtonVisible(false);
    }
    setIsNextButtonVisible(true);
    setActiveTransaction(prevActiveTransaction => prevActiveTransaction - 1);
    setIsExplanationVisible(false);
    setSelectedAnswerOption(null);
    setAnswerStatus(null);
  };

  const [isPlaying, setIsPlaying] = useState(false);

  const [time, setTime] = useState(0);
  const [itemTimeStart, setItemTimeStart] = useState(0);
  const [shouldResetItemTimeStart, setShouldResetItemTimeStart] = useState(false);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (!isPlaying) return;
    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying]);

  const [totalScore, setTotalScore] = useState(0);
  const decisionMutation = useMutation({
    mutationFn: (data) => makeTransactionDecision(data)
  });

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={isBackgroundBlurred}
      isHeaderVisible={true}
      isFooterVisible={isFooterVisible}
      time={time}
      totalScore={totalScore}
      isExplanationVisible={isExplanationVisible}
      explanationName="Emma"
      answerStatus={answerStatus}
      explanationContent={explanationContent}
    >
      {
        isModalVisible &&
          <Modal
            title="Help Emma report her numbers"
            content={<p>Emma has been super busy running her shop. She needs help in reporting her February income statement numbers to investors. She’s unsure if she made a profit or not.</p>}
          />
      }

      {
        isPopupMessagesVisible && 
          <PopupMessages
            messages={popupMessages}
            buttonText={popupMessagesButtonText}
            onButtonClick={popupMessagesButtonHandler}
          />
      }

      {
        isOnGameAvatarVisible &&
          <Avatar 
            name="Emma"
            showName={true}
          />
      }

      {
        isTransactionsVisible &&
          <TransactionsDecisions
            key={activeTransaction}
            transactions={transactions}
            activeTransaction={activeTransaction}
            answerOptions={transactionsAnswerOptions}
            selectedAnswerOption={selectedAnswerOption}
            answerStatus={answerStatus}
            transactionToShow={transactionToShow}
            correctlyAnsweredQuestions={correctlyAnsweredQuestions}
            onAnswerOptionClick={handleAnswerOptionButtonClick}
            isNextButtonVisible={isNextButtonVisible}
            isPrevButtonVisible={isPrevButtonVisible}
            onNextButtonClick={handleNextButtonClick}
            onPrevButtonClick={handlePrevButtonClick}
          />
      }

      {
        isIncomeStatementVisible &&
          <IncomeStatement 
            title="Bean Counter Co. Income Statement January 2024"
            revenues={revenuesTotal}
            expenses={expensesTotal}
          />
      }
    </Layout>
  );
}
