import { useNavigate } from 'react-router-dom';

import InstructorSubPagesLayout from '../../UI/instructor/subPagesLayout';
import InstructorGames from '../../UI/instructor/welcome/games';

import { navigation } from './data';

export default function InstructorWelcomeToAccountingGames() {
  const navigate = useNavigate();

  const handleNextButtonCLick = () => {
    navigate('/instructor/welcome/mastering-fundamentals');
  }

  return (
    <InstructorSubPagesLayout 
      navigation={navigation}
      onNextButtonClick={handleNextButtonCLick}
    >
      <h1>Welcome</h1>
      <h2>Welcome to Accounting Games!</h2>
      <p>We are excited to introduce these new, engaging resources for your financial accounting classes. Our goal is to support your teaching by providing tools that bring accounting principles to life in a fun and memorable way.</p>
      <InstructorGames />
    </InstructorSubPagesLayout>
  );
}
