import './styles.css';

import { gameTemplates } from './data';
import Item from './item';

export default function GamesDashboard({
  role,
  games
}) {
  return (
    <div className="games-wrapper">
      {
        gameTemplates.map((gameTemplate, index) => {
          const relatedGame = games.find(game => gameTemplate.type.includes(game.game_type));
          return (role === 'instructor' || relatedGame) ? (
            <Item 
              key={index}
              gameTemplate={gameTemplate}
              relatedGame={relatedGame}
              role={role}
            />
          ) : (null);
        })
      }
    </div>
  );
}
