import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import { useSoundStatus } from '../../context/SoundStatusContext';

import Layout from '../UI/introGameLayout/layout';

import { scenarioLevelsQuery, scoresStatsQuery, gamesQuery } from './api';

import LevelCompletedSound from './sounds/level-summary.mp3';

import StarEmpty from './images/icon-star-empty.svg';
import StarFilled from './images/icon-star-filled.svg';

import './styles.css';

export default function NormalBalanceSummary() {
  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const { getGuidedPlay } = useAuth();

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const navigate = useNavigate();

  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Normal Balance'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const { soundStatus } = useSoundStatus();

  const levelCompletedEffect = useRef(new Audio(LevelCompletedSound));

  useEffect(() => {
    if (soundStatus === 'enabled') {
      levelCompletedEffect.current.play().catch((e) => console.error('Error playing level completed sound:', e));
    } else {
      if (levelCompletedEffect.current) {
        levelCompletedEffect.current.pause();
      }
    }
  }, [soundStatus]);

  const [rank, setRank] = useState('');
  const [questionsQuantity, setQuestionsQuantity] = useState(0);
  const [score, setScore] = useState(0);
  const [bestScore, setBestScore] = useState(0);

  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId})) || {};
  const { data: scoresStats } = useQuery(scoresStatsQuery({ gameId: currentGameId }));

  const [levelsCount, setLevelsCount] = useState(0);

  useEffect(() => {
    if(scoresStats && scenarioLevels?.length) {
      setLevelsCount(scenarioLevels.length);
      const scenarioLevel = scenarioLevels.find(scenarioLevel => scenarioLevel.level === levelNumber);
      
      const levelScoresStats = scoresStats.find(scoresStat => scoresStat.level === levelNumber);
      setRank(levelScoresStats?.rank);
      setScore(levelScoresStats?.user_points);
      setBestScore(levelScoresStats?.highest_points);
      
      setQuestionsQuantity(scenarioLevel.normal_questions.length);
    }
  }, [scoresStats, scenarioLevels, levelNumber]);

  const firstStarValue = questionsQuantity * 50;
  const secondStarValue = questionsQuantity * 70;
  const thirdStarValue = questionsQuantity * 90;

  const firstStarImage = score >= firstStarValue ? StarFilled : StarEmpty;
  const secondStarImage = score >= secondStarValue ? StarFilled : StarEmpty;
  const thirdStarImage = score >= thirdStarValue ? StarFilled : StarEmpty;

  const handleNextButtonClick = () => {
    if (levelCompletedEffect.current) {
      levelCompletedEffect.current.pause();
    }

    if(levelNumber === 3) {
      navigate(`/normal/badge`);
    } else {
      if(shouldGuidedPagesBeDisplayed || levelNumber === levelsCount) {
        navigate(`/normal/level/${levelNumber}/accuracy`);
      } else {
        navigate(`/normal/level/${levelNumber + 1}/`);
      }
    }
  }

  return (
    <Layout isHeaderVisible={true}>
      <div className="page-content level-summary-page-content">
        <div className="summary-block-container">
          <h1 className="summary-page-title">Level {levelNumber} complete</h1>
          <p className="summary-page-description">Points are determined by both speed and accuracy, and maximum points vary from one level to the next.</p>
          <div className="summary-block">
            <div className="summary-block-item">
              <h3 className="summary-level">Level {levelNumber}</h3>
              {
                rank &&
                  <>
                    <h2 className="summary-rank">{rank}</h2>
                    <div className="summary-stars-rating">
                      <div className="summary-star-block">
                        <img src={firstStarImage} alt="First star" />
                        <span className="summary-star-value">{firstStarValue}</span>
                      </div>
                      <div className="summary-star-block">
                      <img src={secondStarImage} alt="First star" />
                        <span className="summary-star-value">{secondStarValue}</span>
                      </div>
                      <div className="summary-star-block">
                      <img src={thirdStarImage} alt="First star" />
                        <span className="summary-star-value">{thirdStarValue}</span>
                      </div>
                    </div>
                  </>
              }
            </div>
            <div className="summary-block-item">
              <h3 className="summary-score">Score: {score}</h3>
              <h4 className="summary-best-score">Best: {bestScore}</h4>
            </div>
          </div>
        </div>
        <button className="button-red summary-next-button" onClick={handleNextButtonClick}>Next</button>
      </div>
    </Layout>
  );
}