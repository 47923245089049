import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import EmojiiStronglyDisagree from './images/emoji-strongly-disagree.svg';
import EmojiiDisagree from './images/emoji-disagree.svg';
import EmojiiNeutral from './images/emoji-neutral.svg';
import EmojiiAgree from './images/emoji-agree.svg';
import EmojiiStronglyAgree from './images/emoji-strongly-agree.svg';

import IconLinkedin from './images/icon-linkedin.svg';
import IconInstagram from './images/icon-instagram.svg';

import { 
  gamesQuery,
  getScores,
  updateFeedback,
  sessionsQuery
} from './api';

import BackgroundSound from './sounds/feedback-background.mp3';

import Layout from '../UI/introGameLayout/layout';

import './styles.css';

const rateGameOptions = [
  {
    name: 'Strongly Disagree',
    image: EmojiiStronglyDisagree
  },
  {
    name: 'Disagree',
    image: EmojiiDisagree
  },
  {
    name: 'Neutral',
    image: EmojiiNeutral
  },
  {
    name: 'Agree',
    image: EmojiiAgree
  },
  {
    name: 'Strongly Agree',
    image: EmojiiStronglyAgree
  }
]

export default function ClassificationFeedback() {
  const navigate = useNavigate();
  
  const backgroundEffect = useRef(new Audio(BackgroundSound));
  
  const { getUser, isInstructor, isStudent } = useAuth();
  const user = getUser();

  const [currentGameId, setCurrentGameId] = useState(0);

  const { soundStatus } = useSoundStatus();

  useEffect(() => {
    backgroundEffect.current.volume = 0.09;
    backgroundEffect.current.loop = true;
    if (soundStatus === 'enabled') {
      backgroundEffect.current.play().catch((e) => console.error('Error playing background sound:', e));
    } else {
      if (backgroundEffect.current) {
        backgroundEffect.current.pause();
      }
    }
    return () => {
      if (backgroundEffect.current) {
        backgroundEffect.current.pause();
        backgroundEffect.current.currentTime = 0;
      }
    };
  }, [soundStatus]);

  const [rateGameSelected, setRateGameSelected] = useState();
  const handleGameRateClick = (rate) => {
    setRateGameSelected(rate);
  }

  const [whatYouLike, setWhatYouLike] = useState('');
  const [whatYouDontLike, setWhatYouDontLike] = useState('');

  const handleWhatYouLikeChange = (event) => {
    setWhatYouLike(event.target.value);
  }

  const handleWhatYouDontLikeChange = (event) => {
    setWhatYouDontLike(event.target.value);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [warningMessageRate, setWarningMessageRate] = useState('');
  const [warningMessageWhatYouLike, setWarningMessageWhatYouLike] = useState('');
  const [warningMessageWhatYouDontLike, setWarningMessageWhatYouDontLike] = useState('');

  const [isFinalPage, setIsFinalPage] = useState(false);

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  const sessionsResponse = useQuery(sessionsQuery()) || {};
  const sessions = sessionsResponse.data;

  const [sessionId, setSessionId] = useState(0);
  
  const { data: scores, refetch: refetchScores } = useQuery({
    queryKey: ['scores'],
    queryFn: async () => getScores(currentGameId, user?.id),
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    if (games && games.length > 0 && user && sessions) {
      const game = games.find(game => game.game_type.includes('Account Classification'));

      if(game) {
        setCurrentGameId(game.id);

        const session = sessions.find(session => session.user === user.id && session.game === game.id);
        if(session && !sessionId) {
          setSessionId(session.id);
          session.feedback1 && setRateGameSelected(session.feedback1);
          session.feedback2 && setWhatYouLike(session.feedback2);
          session.feedback3 && setWhatYouDontLike(session.feedback3);
        }
      }
    }
  }, [games, user, sessions, sessionId]);
  
  useEffect(() => {
    if (currentGameId && currentGameId !== 0) {
      refetchScores();
    }
  }, [refetchScores, currentGameId]);

  const updateFeedbackMutation = useMutation({
    mutationFn: (data) => updateFeedback(data),
    onSuccess() {
      setIsFinalPage(true);
    }
  });

  const handleSubmitFeedback = () => {
    const whatYouLikeWaring = whatYouLike.length < 10 || whatYouLike.length > 200;
    const whatYouDontLikeWaring = whatYouDontLike.length < 10 || whatYouDontLike.length > 200;
    if (!rateGameSelected || whatYouLikeWaring || whatYouDontLikeWaring) {
      if(!rateGameSelected) {
        setWarningMessageRate('Please make your choice.');
      } else {
        setWarningMessageRate('');
      }
      if(whatYouLikeWaring) {
        setWarningMessageWhatYouLike('Please ensure your feedback is between 10 and 200 characters.');
      } else {
        setWarningMessageWhatYouLike('');
      }
      if(whatYouDontLikeWaring) {
        setWarningMessageWhatYouDontLike('Please ensure your feedback is between 10 and 200 characters.');
      } else {
        setWarningMessageWhatYouDontLike('');
      }
      return;
    }

    setIsLoading(true);
    updateFeedbackMutation.mutate({
      id: sessionId,
      feedback1: rateGameSelected,
      feedback2: whatYouLike,
      feedback3: whatYouDontLike
    });
  };

  const handleViewAssessmentsClick = async () => {
    navigate(`/instructor/dashboard`);
  };

  return (
    <Layout isHeaderVisible={true}>
      {
        isFinalPage ? (
          <div className="page-content final-page-content">
            <div className="final-page-left-part"></div>
            <div className="final-page-right-part">
              <p>Your time and feedback means a lot. Thank you!!</p>
              <p>Follow us on social media to hear more updates from us! 🥁</p>
              <a
                href="https://www.marketgames.io/our-story"
                target="_blank"
                rel="noreferrer"
                className="our-website-link"
              >
                Our Website
              </a>
              <div className="social-links">
                <a href="https://www.linkedin.com/company/themarketgames/" target="_blank" rel="noreferrer">
                  <img src={IconLinkedin} alt="Linkedin"/>
                </a>
                <a href="https://www.instagram.com/themarketgames/?hl=en" target="_blank" rel="noreferrer">
                  <img src={IconInstagram} alt="Instagram"/>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="page-content feedback-page-content">
            <h1>Please Provide Feedback</h1>
            <div className="scores-feedback-container">
              <div className="level-scores">
                {
                  scores && scores.map(score => (
                    <div className="level-score" key={score.level}>
                      <h3>Level {score.level}</h3>
                      <div className="score-result-block">
                        <h4>Points</h4>
                        <h4>{score.total_points.toFixed(0)}</h4>
                      </div>
                      <div className="score-result-block">
                        <h4>Accuracy</h4>
                        <h4>{(score.total_accuracy * 100).toFixed(2)}%</h4>
                      </div>
                    </div>
                  ))
                }
              </div>
              {isStudent() && (
                <div className="level-feedbacks">
                  <h2>We want your feedback ✨</h2>
                  <div className="feedback-row">
                    <label>1. Would you recommend these interactive exercises for this class?</label>
                    <div className="rate-game-input-container">
                      {
                        rateGameOptions.map((rateGameOption, index) => (
                          <button 
                            key={index} 
                            onClick={() => handleGameRateClick(rateGameOption.name)}
                            className={`${rateGameOption.name === rateGameSelected ? 'selected' : ''}`}
                          >
                            <img src={rateGameOption.image} alt={`Emojii ${rateGameOption.name}`} />
                            {rateGameOption.name}
                          </button>
                        ))
                      }
                    </div>
                    <div className="warning">{warningMessageRate}</div>
                  </div>
                  <div className="feedback-row">
                    <label htmlFor="what-you-like">2. What did you like about the game?</label>
                    <textarea
                      rows="4"
                      id="what-you-like"
                      placeholder="Type your thoughts here..."
                      value={whatYouLike}
                      onChange={handleWhatYouLikeChange}
                    />
                    <div className="warning">{warningMessageWhatYouLike}</div>
                  </div>
                  <div className="feedback-row">
                    <label htmlFor="what-you-dont-like">3. What didn’t you like about the game and how could we improve the experience?</label>
                    <textarea
                      rows="4"
                      id="what-you-dont-like"
                      placeholder="Type your thoughts here..."
                      value={whatYouDontLike}
                      onChange={handleWhatYouDontLikeChange}
                    />
                    <div className="warning">{warningMessageWhatYouDontLike}</div>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmitFeedback}
                    className={`button-red button-submit ${isLoading ? 'loading' : ''}`}
                  >
                    {!isLoading && 'Submit'}
                  </button>
                </div>
              )}
              {isInstructor() && (
                <div className="instructor-info">
                  <a
                    href="https://calendly.com/torsor/15min?month=2024-02"
                    className="button-navi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Schedule Appointment
                  </a>
                  <ul>
                    <li>To learn more about our accounting games launching Summer 2024</li>
                    <li>To set up a pilot game for your class</li>
                  </ul>
                  <button className="button-red" onClick={handleViewAssessmentsClick}>
                    View Assessments
                  </button>
                </div>
              )}
            </div>
          </div>
        )
      }
    </Layout>
  )
}
