import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import { 
  login as apiLogin,
  getUsers,
  getGamesSessions,
  getGames,
  getCourseMembers
} from './api';

import { getHighestActiveGame } from '../../utils';
import useAuth from '../../auth/useAuth';

import './styles.css';

export default function Login() {
  const navigate = useNavigate();
  const { 
    login,
    isUserAuthenticated,
    getUser,
    saveUser,
    isInstructor,
    isStudent,
    saveCurrentGameId,
    saveCurrentScenarioId
  } = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const { data: gamesSessions, refetch: refetchSessions } = useQuery({
    queryKey: ['gamesSessions'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: false
  });

  const { data: games, refetch: refetchGames } = useQuery({
    queryKey: ['games'],
    queryFn: getGames,
    enabled: false
  });

  const { data: courseMembers, refetch: refetchCourseMembers } = useQuery({
    queryKey: ['courseMembersLogin'],
    queryFn: getCourseMembers,
    refetchOnWindowFocus: false,
    enabled: false
  });

  const navigateTo = useCallback(() => {
    const user = getUser();
    const userIsInstructor = isInstructor();
    const userIsStudent = isStudent();

    let redirectUrl = '';
    
    if(userIsInstructor) {
      const courseMember = courseMembers?.find(courseMember => courseMember.user_id === user.id);
      if(courseMember?.view_welcome) {
        redirectUrl = '/instructor/dashboard';
      } else {
        redirectUrl = '/acc/welcome';
      }
    } else if(userIsStudent) {
      if(gamesSessions && gamesSessions.length && courseMembers?.length) {
        if (user && user.alias_name) {
          if(games?.length) {
            redirectUrl = '/games';
          }
        } else {
          redirectUrl = '/onboarding';
        }
      } else {
        redirectUrl = '/acc/welcome';
      }
    } else {
      redirectUrl = '/acc/welcome';
    }
    navigate(redirectUrl);
  }, [games, getUser, isInstructor, isStudent, navigate, courseMembers]);

  useEffect(() => {
    if (isUserAuthenticated()) {
      refetchGames();
      refetchSessions();
      refetchCourseMembers();
    }
  }, [isUserAuthenticated, refetchGames, refetchSessions, refetchCourseMembers]);
  
  useEffect(() => {
    if (games && gamesSessions && courseMembers?.length) {
      const game = getHighestActiveGame(games);
      if(game) {
        saveCurrentGameId(game.id);
        saveCurrentScenarioId(game.scenario);
      }
      navigateTo();
    }
  }, [games, gamesSessions, courseMembers]);

  const loginMutation = useMutation({
    mutationFn: (data) => apiLogin(data),
    onSuccess: (token) => {
      login(token);
      refetchUsers();
    },
    onError() {
      setWarningMessage('Login details are incorrect, please try again');
      setIsLoading(false);
    }
  });

  const handleLogin = () => {
    if (!navigator.onLine) {
      setWarningMessage('No internet connection, please check your internet and try again.');
      return;
    }
    if (username.trim() && password.trim()) {
      setIsLoading(true);
      loginMutation.mutate({ username: username.trim().toLowerCase(), password: password.trim() });
    } else {
      setWarningMessage('Please enter both username and password.');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value.trim());
    
    setWarningMessage('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.trim());
    
    setWarningMessage('');
  };

  const { data: users, refetch: refetchUsers, isSuccess } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
    enabled: false
  });

  useEffect(() => {
    if (isSuccess && users) {
      const currentUser = users.find(user => user.username === username.toLowerCase());
      if (currentUser) {
        saveUser(currentUser);
        refetchGames();
      } else {
        setIsLoading(false);
        setWarningMessage('User not found. Please try again.');
      }
    }
  }, [users, username, navigate, saveUser, isSuccess, refetchGames]);
  

  return (
    <div className="page-content page-content-login">
      <div className="login-page-container">
        <h1>Master the language of business</h1>
        <h3>Fun <span className='red-highlight'>active learning</span> games for financial accounting courses </h3>
        <div className="form">
          <div className="form-row">
            <div className="input-container">
              <label>Username</label>
              <input 
                type="text"
                value={username}
                onChange={handleUsernameChange}
                onKeyUp={handleKeyPress}
                placeholder="Username"
              />
            </div>
            <div className="input-container">
              <label>Password</label>
              <input 
                type="password"
                value={password}
                onChange={handlePasswordChange}
                onKeyUp={handleKeyPress}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="form-row">
            <button
              className={`button-red button-submit ${isLoading ? 'loading' : ''}`}
              onClick={handleLogin}
              disabled={isLoading}
            >
              {!isLoading && 'Login'}
            </button>
          </div>
          <div className="form-row">
            {warningMessage && <span className="login-warning">{warningMessage}</span>}
          </div>
        </div>
      </div>
    </div>
  );
}
