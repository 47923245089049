import React, { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';

import { useSoundStatus } from '../../../../context/SoundStatusContext';

import Avatar from './../avatar';
import './styles.css';

import TypingSound from './sounds/typing.mp3';
import MessageAppearsSound from './sounds/message-appears.mp3';

export default function Message({
  name,
  text,
  alignment,
  onTypingCompleted,
  shouldType,
  fadeIn,
  displayAvatar,
  size = '',
  shouldPlayAppearMessage = true
}) {
  const typedRef = useRef(null);
  const typingEffect = useRef(null);
  const messageAppearsEffect = useRef(null);
  const [displayedText, setDisplayedText] = useState('');

  const { soundStatus } = useSoundStatus();

  useEffect(() => {
    if (!typingEffect.current) {
      typingEffect.current = new Audio(TypingSound);
    }
    if (!messageAppearsEffect.current) {
      messageAppearsEffect.current = new Audio(MessageAppearsSound);

      if (soundStatus === 'enabled' && shouldPlayAppearMessage) {
        messageAppearsEffect.current.play().catch(e => console.log('Error playing message appears sound:', e));
      }
    }

  }, []);

  useEffect(() => {
    let typedInstance;

    if (shouldType) {
      const typingDelayTimeout = setTimeout(() => {
        if (soundStatus === 'enabled') {
          typingEffect.current.volume = 0.2;
          typingEffect.current.loop = true;
          typingEffect.current.play().catch(e => console.log('Error playing typing sound:', e));
        }

        const options = {
          strings: [text],
          typeSpeed: 20,
          smartBackspace: true,
          loop: false,
          showCursor: false,
          contentType: 'html',
          onComplete: () => {
            if (onTypingCompleted) {
              onTypingCompleted();
            }
            typingEffect.current.pause();
          },
        };

        typedInstance = new Typed(typedRef.current, options);
      }, 1000);

      return () => {
        clearTimeout(typingDelayTimeout);
        if (typedInstance) {
          typedInstance.destroy();
        }
        if (typingEffect.current) {
          typingEffect.current.pause();
        }
      };
    } else {
      setDisplayedText(text);
      const displayTimeout = setTimeout(() => {
        if (onTypingCompleted) {
          onTypingCompleted();
        }
      }, 500);

      return () => clearTimeout(displayTimeout);
    }
  }, [shouldType, text, onTypingCompleted, soundStatus]);

  const messageContent = shouldType
    ? <span ref={typedRef}></span>
    : <span dangerouslySetInnerHTML={{ __html: displayedText }}></span>;

  return (
    <div className={`popup-message-container ${name.toLowerCase()}-message-container ${alignment}-aligned ${fadeIn ? 'fade-in' : ''}`}>
      {displayAvatar && <Avatar name={name} />}
      <div className={`popup-message-text-wrapper ${!displayAvatar ? 'without-avatar' : ''} ${size}`}>
        <span className="popup-message-name">{name}</span>
        <div className="popup-message-text">
          {messageContent}
        </div>
      </div>
    </div>
  );
}
