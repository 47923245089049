import axiosClient from './axiosClient';

export async function fetchPageData(url, allItems = []) {
  const response = await axiosClient.get(url);
  const items = [...allItems, ...response.data.results];
  
  if (response.data.next) {
    return fetchPageData(response.data.next, items);
  } else {
    return items;
  }
}