import axiosClient from '../../api/axiosClient.js';

async function fetchGamesPage(url, allGames = []) {
  const response = await axiosClient.get(url);
  const games = [...allGames, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesPage(response.data.next, games);
  } else {
    return games;
  }
}

async function getGames() {
  return fetchGamesPage(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false
  };
};

async function fetchClassificationScores(url, allScores = []) {
  const response = await axiosClient.get(url);
  const scores = [...allScores, ...response.data.results];
  
  if (response.data.next) {
    return fetchClassificationScores(response.data.next, scores);
  } else {
    return scores;
  }
}

export async function getClassificationScores(gameId) {
  return fetchClassificationScores(`/api/classification/scores/?game=${gameId}`);
}

async function fetchProfitScores(url, allScores = []) {
  const response = await axiosClient.get(url);
  const scores = [...allScores, ...response.data.results];
  
  if (response.data.next) {
    return fetchProfitScores(response.data.next, scores);
  } else {
    return scores;
  }
}

export async function getProfitScores(gameId) {
  return fetchProfitScores(`/api/profit/scores/?game=${gameId}`);
}

async function fetchClassificationScoresByQuestion(url, allScores = []) {
  const response = await axiosClient.get(url);
  const scores = [...allScores, ...response.data.results];
  
  if (response.data.next) {
    return fetchClassificationScoresByQuestion(response.data.next, scores);
  } else {
    return scores;
  }
}

export async function getClassificationScoresByQuestion(gameId) {
  return fetchClassificationScoresByQuestion(`/api/classification/scores/by-question/?game=${gameId}`);
}

export async function getProfitScoresByQuestion(gameId) {
  const response = await axiosClient.get(`/api/profit/scores/principles/?game=${gameId}/`);
  return response.data;
}

async function fetchScenarioLevelsPage(url, allScenarioLevels = []) {
  const response = await axiosClient.get(url);
  const scenarioLevels = [...allScenarioLevels, ...response.data.results];
  
  if (response.data.next) {
    return fetchScenarioLevelsPage(response.data.next, scenarioLevels);
  } else {
    return scenarioLevels;
  }
}

export async function getScenarioLevels(scenarioId) {
  return fetchScenarioLevelsPage(`/api/games/scenario-levels/?scenarios=${scenarioId}`);
}

async function fetchCourseMembersPage(url, allCourseMembers = []) {
  const response = await axiosClient.get(url);
  const courseMembers = [...allCourseMembers, ...response.data.results];
  
  if (response.data.next) {
    return fetchCourseMembersPage(response.data.next, courseMembers);
  } else {
    return courseMembers;
  }
}

export async function getCourseMembers() {
  return fetchCourseMembersPage(`/api/users/course-members/`);
}