import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../UI/introGameLayout/layout';

import useAuth from '../../auth/useAuth';

import BackgroundSound from '../UI/introGameLayout/backgroundSound';
import PopupMessages from '../UI/introGameLayout/popupMessages';

import BackgroundTutorialIntro from './images/tutorial-intro-background.jpg';
import BackgroundImageOtherLevels from './images/other-levels-background.jpg';

import IntroProcessSound from './sounds/intro-process.mp3';

import {
  popupMessagesLevel1,
  popupMessagesLevel2,
  popupMessagesLevel3,
  popupMessagesLevel4
} from './data';

import './styles.css';

export default function BalancedEquationIntro() {
  const navigate = useNavigate();

  const { getGuidedPlay } = useAuth();

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);
  
  const handleBottomMessageButtonClick = () => {
    let redirectUrl;
    if(levelNumber) {
      if(levelNumber <= 3) {
        redirectUrl = `/balanced-equation/level/${levelNumber}/tutorial`;
      } else {
        redirectUrl = `/balanced-equation/level/${levelNumber}/`;
      }
    } else {
      if(shouldGuidedPagesBeDisplayed) {
        redirectUrl = `/balanced-equation/level/${levelNumber + 1}/intro/`;
      } else {
        redirectUrl = `/balanced-equation/level/${levelNumber + 1}/`;
      }
    }

    navigate(redirectUrl);
  }

  const BackgroundImage = levelNumber
    ? levelNumber === 1
      ? BackgroundTutorialIntro
      : BackgroundImageOtherLevels
    : BackgroundTutorialIntro;

  const popupMessages = {
    1: popupMessagesLevel1,
    2: popupMessagesLevel2,
    3: popupMessagesLevel3,
    4: popupMessagesLevel4
  };

  const currentPagePopupMessages = popupMessages[levelNumber];

  const buttonText = levelNumber === 1
    ? 'Let\'s go'
    : `Start Level ${levelNumber}`;

  return(
    <Layout
      isHeaderVisible={true}
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={levelNumber === 1}
      isContentVertical={true}
    >
      <BackgroundSound 
        sound={IntroProcessSound}
      />
      <PopupMessages
        messages={currentPagePopupMessages}
        buttonText={buttonText}
        onButtonClick={handleBottomMessageButtonClick}
        shouldType={false}
      />
    </Layout>
  );
}
