import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import './styles.css';

import { 
  gamesQuery
} from './api';
import GamesDashboard from '../UI/gamesDashboard';

export default function InstructorGames() {
  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  const { isInstructor } = useAuth();
  const role = isInstructor() ? 'instructor' : 'student';

  return (
    <div className="container instructor-games-container">
      <h1>Business Games</h1>
      {
        games && 
          <GamesDashboard 
            games={games}
            role={role}
          />
      }
    </div>
  );
}
