import SignMinus from './images/icon-minus.svg';
import SignPlus from './images/icon-plus.svg';

export const popupMessagesLevel1 = [
  {
    name: 'Bailey',
    text: 'Today, we\'re doing a training exercise called <b>The Balanced Equation game</b>.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'We\'ll use <b><a href="https://www.investopedia.com/terms/a/accounting-equation.asp" target="_blank" rel="noreferrer">the Accounting Equation concept</a></b> to illustrate how every transaction must balance, providing you with a broader perspective on how business transactions can affect a company\'s overall financial health.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'In this exercise, you\'ll encounter different business transactions and need to identify their impact on the Balance Sheet elements: Assets, Liabilities, and Equity.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'We\'ll start with Level 1, where your primary task is to decide whether each transaction increases or decreases these elements. <br><br>That said, let\'s begin with Level I.',
    alignment: 'right'
  },
];

export const popupMessagesLevel2 = [
  {
    name: 'Bailey',
    text: 'Congrats on finishing Level I! Hopefully you’ve gained a general understanding of how transactions impact Balance Sheet elements.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'In this level, let’s take it up a notch by introducing a new decision that you’ll have to make.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'In addition to determining how a transaction affects the Balance Sheet elements, you\'ll also need to decide which specific accounts are typically impacted by the transaction.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> If you find a question challenging, consider how the transaction affects the Balance Sheet elements before identifying the specific accounts involved. <br><br>All the best!',
    alignment: 'right'
  }
];

export const popupMessagesLevel3 = [
  {
    name: 'Bailey',
    text: 'Congrats on completing Level 2! Hopefully, you’ve gained a better understanding of how a transaction impacts an element and specific accounts.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'In this level, let’s step up our game by introducing <b><a href="https://www.investopedia.com/ask/answers/022615/what-double-entry-bookkeeping-and-how-does-it-work-general-ledger.asp" target="_blank" rel="noreferrer">the Double Entry system</a></b> and adding debits and credits into the mix.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'In addition to determining how a transaction impacts the Balance Sheet elements and specific accounts, you\'ll also need to decide whether the affected account should be debited or credited.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> Apply your understanding of each account’s normal balance to decide whether it should be debited or credited. <br><br>Sending you positive vibes!',
    alignment: 'right'
  }
];

export const popupMessagesLevel4 = [
  {
    name: 'Bailey',
    text: 'Well done on conquering level 3 and reaching the final level!',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'In this level, we’re going to incorporate some revenue and expense-related transactions to illustrate how changes in the income statement affect the balance sheet.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Since all income statement balances are ultimately transferred to the retained earnings account on the Balance Sheet, we will use the retained earnings account instead of individual revenue and expense accounts. This will help you visualize the double-entry system and its effects on both the balance sheet and income statement.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> Use the Retained Earnings (Revenues) account for all revenues and the Retained Earnings (Expense) account for all expenses. <br><br>Best of luck!',
    alignment: 'right'
  }
];

export const topOptions = [
  {
    id: 1,
    order: 1,
    accountItemId: 3,
    name: "Assets",
    description: "An asset refers to a valuable item that a company possesses, owns, and/or controls with the anticipation that the item will either be converted into cash or provide future benefits."
  },
  {
    id: 2,
    order: 2,
    accountItemId: 4,
    name: "Liabilities",
    description: "A liability is what a company owes. It's typically an obligation or debt that a company owes to external parties."
  },
  {
    id: 3,
    order: 3,
    accountItemId: 5,
    name: "Equity",
    description: "Equity is the net difference between what a company owns (Assets) and what it owes (Liabilities). It represents the ownership interest of the company's owners.",
    type: ""
  }
];

export const changeTypeOptions = [
  {
    id: 6,
    order: 4,
    accountItemId: 43,
    name: <img src={SignPlus} alt="Plus" />,
    description: "Increase",
    type: "change"
  },
  {
    id: 7,
    order: 5,
    accountItemId: 44,
    name:  <img src={SignMinus} alt="Minus" />,
    description: "Decrease",
    type: "change"
  }
];

export const coreTypeOptions = [
  {
    id: 19,
    order: 6,
    accountItemId: 17,
    name: "Cash",
    description: "Cash is characterized as funds or any form of payment that banks will acknowledge for deposit and promptly credit to a company's account, including checks, money orders, or bank drafts.",
    type: "core"
  },
  {
    id: 21,
    order: 8,
    accountItemId: 19,
    name: "Inventory",
    description: "Inventory refers to the stock of goods, raw materials, work-in-progress, or finished products that a company holds for the purpose of selling, typically within one year.",
    type: "core"
  },
  {
    id: 22,
    order: 9,
    accountItemId: 20,
    name: "Property, Plant, and Equipment (PPE)",
    description: "PPE refers to a category of tangible assets that a business uses to generate revenue. These assets are expected to have a useful life extending beyond one year or the normal operating cycle.",
    type: "core"
  },
  {
    id: 23,
    order: 10,
    accountItemId: 21,
    name: "Accounts Payable",
    description: "Accounts payable (AP) signifies the sums that a company is obligated to pay to its suppliers or vendors, with payments typically anticipated within a year.",
    type: "core"
  },
  {
    id: 25,
    order: 12,
    accountItemId: 6,
    name: "Owner's Capital",
    description: "Owner's Capital, in accounting, refers to the total amount of investment made by the owner or owners in a business. It represents the initial contributions made by the owner(s) plus any additional investments over time.",
    type: "core"
  }
];

export const transactionTypeOptions = [
  {
    id: 31,
    order: 6,
    accountItemId: 35,
    name: "Debit",
    description: "A debit is an entry on the left side of a double-entry bookkeeping system. Debits and credits are used to ensure that the accounting equation (Assets = Liabilities + Equity) remains balanced.",
    type: "transaction"
  },
  {
    id: 32,
    order: 7,
    accountItemId: 36,
    name: "Credit",
    description: "A credit is an entry on the right side of a double-entry bookkeeping system. Debits and credits are used to ensure that the accounting equation (Assets = Liabilities + Equity) remains balanced.",
    type: "transaction"
  }
];

export const popupMessagesTutorialIntro = [
  {
    name: 'Bailey',
    text: 'Before we get started, let’s walk through a business transaction to better prepare you for this exercise.',
    alignment: 'left'
  }
];

export const tutorialQuestions = [
  {
    id: 6,
    value: 50000,
    description: "Emma launched her coffee shop business with $50,000 in cash she received from an investor. In return, she granted the investor partial ownership of her business."
  }
];

export const tutorialCorrectDecisions = [
  [
    {
      type: "change",
      itemOption: 3,
      selectedOption: 6
    },
    {
      type: "change",
      itemOption: 5,
      selectedOption: 6
    },
  ],
  [
    {
      type: "change",
      itemOption: 3,
      selectedOption: 6
    },
    {
      type: "core",
      itemOption: 3,
      selectedChange: 43,
      selectedOption: 19
    },
    {
      type: "change",
      itemOption: 5,
      selectedOption: 6
    },
    {
      type: "core",
      itemOption: 5,
      selectedChange: 43,
      selectedOption: 25
    },
  ],
  [
    {
      type: "change",
      itemOption: 3,
      selectedOption: 6
    },
    {
      type: "core",
      itemOption: 3,
      selectedChange: 43,
      selectedOption: 19
    },
    {
      type: "transaction",
      itemOption: 3,
      selectedOption: 31
    },
    {
      type: "change",
      itemOption: 5,
      selectedOption: 6
    },
    {
      type: "core",
      itemOption: 5,
      selectedChange: 43,
      selectedOption: 25
    },
    {
      type: "transaction",
      itemOption: 5,
      selectedOption: 32
    },
  ]
];