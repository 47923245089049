import { useNavigate } from 'react-router-dom';

import InstructorSubPagesLayout from '../../UI/instructor/subPagesLayout';

import { navigation } from './data';

export default function InstructorWelcomeWaysToUse() {
  const navigate = useNavigate();

  const handlePrevButtonCLick = () => {
    navigate('/instructor/welcome/how-scoring-works');
  }

  const handleNextButtonCLick = () => {
    navigate('/instructor/welcome/setting-up-for-class');
  }

  return (
    <InstructorSubPagesLayout 
      onPrevButtonClick={handlePrevButtonCLick}
      onNextButtonClick={handleNextButtonCLick}
      navigation={navigation}
    >
      <h1>When and how to use</h1>
      <h2>When to Use</h2>
      <p>It’s best to introduce these games early in the semester while covering the elements of financial statements, their normal balances, and the principles that influence how transactions affect these elements.</p>
      <hr />
      <h2>How to Use</h2>
      <p>Our games is best used as a review activity to help reinforce foundational learning. Here are a few ways you could deploy it in your course:</p>
      <ul>
        <li><b>Flip Assignment:</b> Assign homework readings and conduct in-class games to evaluate students' understanding of the fundamentals  (classification, normal balance, double entry, principles)</li>
        <li><b>Practice Exercises:</b> Provide the games as a resource for students to help them practice and master foundational accounting concepts on their own time.</li>
        <li><b>Interactive Lectures:</b> Incorporate the games into your lectures to break up the lecture format and engage students with interactive, hands-on learning experiences. A few examples:
          <ul>
            <li>Introducing the accounting concept, the double entry system, and how all things must balance (<strong>Balanced Equation, The Normal Balance</strong>)</li>
            <li>Introducing a complex transaction (<strong>Balanced Equation</strong>)</li>
            <li>Discussing foundational principles such as revenue recognition, matching principles. (<strong>The Profit</strong>)</li>
            <li>Discussing the importance of the 5 Elements and financial reporting (<strong>The Classification game</strong>)</li>
          </ul>
        </li>
      </ul>
    </InstructorSubPagesLayout>
  );
}
