export const navigation = [
  {
    title: 'Overall',
    url: '/instructor/insights/overall/leaderboard',
    children: [
      {
        title: 'Leaderboard',
        url: '/instructor/insights/overall/leaderboard',
      },
      {
        title: 'Accuracy',
        url: '/instructor/insights/overall/accuracy',
      },
      {
        title: 'Average time',
        url: '/instructor/insights/overall/average-time',
      },
    ]
  },
  {
    title: 'Decision Insights',
    url: '/instructor/insights/decision-insights/classification/1',
    children: [
      {
        title: 'Classification',
        url: '/instructor/insights/decision-insights/classification/1',
      },
      {
        title: 'Normal balance',
        url: '/instructor/insights/decision-insights/normal/1',
      },
      {
        title: 'Balanced Equation',
        url: '/instructor/insights/decision-insights/balanced/1',
      }
    ]
  }
];