import axiosClient from '../../api/axiosClient.js';

async function fetchGamesPage(url, allGames = []) {
  const response = await axiosClient.get(url);
  const games = [...allGames, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesPage(response.data.next, games);
  } else {
    return games;
  }
}

async function getGames() {
  return fetchGamesPage(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function fetchScenarioLevelsPage(url, allScenarioLevels = []) {
  const response = await axiosClient.get(url);
  const scenarioLevels = [...allScenarioLevels, ...response.data.results];
  
  if (response.data.next) {
    return fetchScenarioLevelsPage(response.data.next, scenarioLevels);
  } else {
    return scenarioLevels;
  }
}

async function getScenarioLevels(scenarioId) {  
  return scenarioId ? fetchScenarioLevelsPage(`/api/games/scenario-levels/?scenarios=${scenarioId}`) : [];
}

export const scenarioLevelsQuery = ({scenarioId}) => {
  return {
    queryKey: ['scenarioLevels', scenarioId],
    queryFn: async () => getScenarioLevels(scenarioId),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !!scenarioId
  };
};

async function getScenarioLevel({scenarioId, level}) {
  const response = await axiosClient.get(`/api/games/scenario-levels/?scenarios=${scenarioId}&level=${level}`);
  return response.data.results[0];
}

export const scenarioLevelQuery = ({scenarioId, level}) => {
  return {
    queryKey: ['scenarioLevel', scenarioId],
    queryFn: async () => getScenarioLevel({scenarioId, level}),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !!scenarioId
  };
};

async function fetchItemOptionsPage(url, allItemOptions = []) {
  const response = await axiosClient.get(url);
  const itemOptions = [...allItemOptions, ...response.data.results];
  
  if (response.data.next) {
    return fetchItemOptionsPage(response.data.next, itemOptions);
  } else {
    return itemOptions;
  }
}

async function getItemOptions() {  
  return fetchItemOptionsPage(`/api/classification/item-options/`);
}

export const itemOptionsQuery = () => {
  return {
    queryKey: ['itemOptions'],
    queryFn: async () => getItemOptions(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function fetchItemQuestionsPage(url, allItemQuestions = []) {
  const response = await axiosClient.get(url);
  const itemQuestions = [...allItemQuestions, ...response.data.results];
  
  if (response.data.next) {
    return fetchItemQuestionsPage(response.data.next, itemQuestions);
  } else {
    return itemQuestions;
  }
}

async function getItemQuestions(scenarioLevelId) {  
  return scenarioLevelId ? fetchItemQuestionsPage(`/api/classification/item-questions/?scenario_level=${scenarioLevelId}`) : [];
}

export const itemQuestionsQuery = (scenarioLevelId) => {
  return {
    queryKey: ['itemQuestions', scenarioLevelId],
    queryFn: async () => getItemQuestions(scenarioLevelId),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function fetchItemsPage(url, allItems = []) {
  const response = await axiosClient.get(url);
  const items = [...allItems, ...response.data.results];
  
  if (response.data.next) {
    return fetchItemsPage(response.data.next, items);
  } else {
    return items;
  }
}

async function getItems() {
  return fetchItemsPage(`/api/games/items/`);
}

export const itemsQuery = () => {
  return {
    queryKey: ['items'],
    queryFn: async () => getItems(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

export async function makeDecision({user, game, question, selected_option, time_elapsed, timestamp}) {
  const { data } = await axiosClient.post(`api/classification/decisions/`, {
    user,
    game,
    question,
    selected_option,
    time_elapsed,
    timestamp
  });

  return data;
}

async function fetchDecisionsPage(url, allDecisions = []) {
  const response = await axiosClient.get(url);
  const decisions = [...allDecisions, ...response.data.results];
  
  if (response.data.next) {
    return fetchDecisionsPage(response.data.next, decisions);
  } else {
    return decisions;
  }
}

export async function getDecisions({levelNumber, userId, gameId}) {
  return fetchDecisionsPage(`/api/classification/decisions/?level=${levelNumber}&user=${userId}&game=${gameId}`);
}

async function fetchGamesSessions(url, allSessions = []) {
  const response = await axiosClient.get(url);
  const sessions = [...allSessions, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesSessions(response.data.next, sessions);
  } else {
    return sessions;
  }
}

export async function getGamesSessions() {
  return fetchGamesSessions(`/api/games/sessions/`);
}

export async function getScoresStats({gameId}) {
  const response = await axiosClient.get(`/api/classification/scores/stats/${gameId}/`);
  return response.data;
}

export const scoresStatsQuery = ({gameId}) => {
  return {
    queryKey: ['scoresStats'],
    queryFn: async () => getScoresStats({gameId}),
    refetchOnWindowFocus: false,
    enabled: !!gameId
  };
};

async function fetchScoresByQuestion(url, allScores = []) {
  const response = await axiosClient.get(url);
  const scores = [...allScores, ...response.data.results];
  
  if (response.data.next) {
    return fetchScoresByQuestion(response.data.next, scores);
  } else {
    return scores;
  }
}

export async function getScoresByQuestion({gameId}) {
  return fetchScoresByQuestion(`/api/classification/scores/by-question/?game=${gameId}`);
}

export const scoresByQuestionQuery = ({gameId}) => {
  return {
    queryKey: ['scoresByQuestion'],
    queryFn: async () => getScoresByQuestion({gameId}),
    refetchOnWindowFocus: false,
    enabled: !!gameId
  };
};

async function fetchSessionsPage(url, allSessions = []) {
  const response = await axiosClient.get(url);
  const sessions = [...allSessions, ...response.data.results];
  
  if (response.data.next) {
    return fetchSessionsPage(response.data.next, sessions);
  } else {
    return sessions;
  }
}

async function getSessions() {
  return fetchSessionsPage(`/api/games/sessions/`);
}

export const sessionsQuery = () => {
  return {
    queryKey: ['sessions'],
    queryFn: async () => getSessions(),
    refetchOnWindowFocus: false
  };
};

async function fetchScores(url, allScores = []) {
  const response = await axiosClient.get(url);
  const scores = [...allScores, ...response.data.results];
  
  if (response.data.next) {
    return fetchScores(response.data.next, scores);
  } else {
    return scores;
  }
}

export async function getScores(game, user) {
  return fetchScores(`/api/classification/scores/?game=${game}&user=${user}`);
}

export async function updateFeedback({id, feedback1, feedback2, feedback3}) {
  const { data } = await axiosClient.patch(`/api/games/sessions/${id}/`, {
    feedback1,
    feedback2,
    feedback3
  });

  return data;
}

export async function createGameSession({game, user}) {
  const { data } = await axiosClient.post(`/api/games/sessions/`, {
    game,
    user
  });

  return data;
}

export async function updateGameSession({id}) {
  const { data } = await axiosClient.patch(`/api/games/sessions/${id}/`, {
    is_ready: true
  });

  return data;
}