import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import { scenarioLevelsQuery, scoresStatsQuery, gamesQuery } from './api';

import BadgeMaster from './images/badge-master.png';
import BadgeWarrior from './images/badge-warrior.png';
import BadgeNewbie from './images/badge-newbie.png';
import BadgeNoob from './images/badge-noob.png';

import BadgeMasterSound from './sounds/badge-master.mp3';
import BadgeWarriorSound from './sounds/badge-warrior.mp3';
import BadgeNewbieSound from './sounds/badge-newbie.mp3';
import BadgeNoobSound from './sounds/badge-noob.mp3';

import Layout from '../UI/introGameLayout/layout';

import { fadeOutSound } from '../../utils';

import './styles.css';

export default function NormalBalanceBadge() {
  const navigate = useNavigate();
  
  const { getGuidedPlay } = useAuth();
  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const { soundStatus } = useSoundStatus();
  const currentSoundEffect = useRef(null);

  const masterEffect = useRef(new Audio(BadgeMasterSound));
  const warriorEffect = useRef(new Audio(BadgeWarriorSound));
  const newbieEffect = useRef(new Audio(BadgeNewbieSound));
  const noobEffect = useRef(new Audio(BadgeNoobSound));

  const [score, setScore] = useState(0);
  const [badge, setBadge] = useState('');

  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Normal Balance'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId}));
  const { data: scoresStats } = useQuery(scoresStatsQuery({ gameId: currentGameId }));

  useEffect(() => {
    if(scoresStats && scenarioLevels?.length) {
      const totalPoints = scoresStats.filter(scoresStat => scoresStat.level <= 3).reduce((acc, scoresStat) => acc + scoresStat.user_points, 0);
      setScore(totalPoints);
      
      const totalQuestions = scenarioLevels.filter(scenarioLevel => scenarioLevel.level <= 3).reduce((acc, scenarioLevel) => acc + scenarioLevel.normal_questions.length, 0);
      const calculatedBadge = totalPoints >= 90 * totalQuestions
        ? 'Master'
        : totalPoints >= 70 * totalQuestions
          ? 'Warrior'
          : totalPoints >= 50 * totalQuestions
            ? 'Newbie'
            : 'Noob';

      setBadge(calculatedBadge);
    }
  }, [scoresStats, scenarioLevels]);

  useEffect(() => {
    if(badge) {
      if (soundStatus === 'enabled') {
        let soundEffect;
        switch (badge) {
          case 'Master':
            soundEffect = masterEffect.current;
            break;
          case 'Warrior':
            soundEffect = warriorEffect.current;
            break;
          case 'Newbie':
            soundEffect = newbieEffect.current;
            break;
          case 'Noob':
            soundEffect = noobEffect.current;
            break;
          default:
            return;
        }
  
        currentSoundEffect.current = soundEffect;
        currentSoundEffect.current.volume = 0.3;
        soundEffect.play().catch(e => console.error('Error playing sound:', e));
      } else {
        if (currentSoundEffect.current) {
          currentSoundEffect.current.pause();
        }
      }

      return () => {
        if (currentSoundEffect.current) {
          currentSoundEffect.current.pause();
          currentSoundEffect.current.currentTime = 0;
        }
      };
    }
  }, [badge, soundStatus]);

  const badgeImages = {
    Master: BadgeMaster,
    Warrior: BadgeWarrior,
    Newbie: BadgeNewbie,
    Noob: BadgeNoob,
  };

  const badgeDescriptions = {
    Master: 'Outstanding! You’ve earned the Accounting Master badge!',
    Warrior: 'Wonderful! You’ve earned the Accounting Warrior badge!',
    Newbie: 'Not Bad! You’ve earned the Accounting Newbie badge!',
    Noob: 'You’ve earned the Accounting Noob badge!'
  };

  useEffect(() => {
    if (score > 0) {
      const fadeOutTimer = setTimeout(() => {
        if (currentSoundEffect.current) {
          fadeOutSound(currentSoundEffect.current, 2000);
        }
      }, 8000);
      
      const navigateTimer = setTimeout(() => {
        let redirectUrl;

        if(shouldGuidedPagesBeDisplayed) {
          redirectUrl = '/normal/level/3/accuracy';
        } else {
          redirectUrl = '/normal/level/4/';
        }
        navigate(redirectUrl);
      }, 10000);

      return () => {
        clearTimeout(fadeOutTimer);
        clearTimeout(navigateTimer);
      };
    }
  }, [score, navigate]);

  return (
    <Layout isHeaderVisible={true}>
      <div className="page-content badge-page-content">
        <h1 className="badge-page-title">Level 3</h1>
        {
          score > 0 &&
            <>
              <p className="badge-page-description">{badgeDescriptions[badge]}</p>
              <div className="badge-container">
                <div className="badge-info">
                  <h3 className="badge-game-type">Normal Balance</h3>
                  <h2 className="badge-rank">{badge}</h2>
                </div>
                <img className="badge-image" src={badgeImages[badge]} alt={`${badge} Badge`} />
              </div>
            </>
        }
      </div>
    </Layout>
  );
}