import axios from 'axios';
import axiosClient from '../../api/axiosClient.js';
import { fetchPageData } from '../../api/utils.js';

import { BASE_URL } from '../../api/endpoints';

export async function login({username, password}) {
  const { data } = await axios.post(`${BASE_URL}/users/auth-obtain-token/`, {
    username,
    password
  });

  return data.token;
}

async function fetchUsersPage(url, allUsers = []) {
  const response = await axiosClient.get(url);
  const users = [...allUsers, ...response.data.results];
  
  if (response.data.next) {
    return fetchUsersPage(response.data.next, users);
  } else {
    return users;
  }
}

export async function getUsers() {
  return fetchUsersPage(`${BASE_URL}/api/users/users/`);
}

async function fetchGamesSessions(url, allSessions = []) {
  const response = await axiosClient.get(url);
  const sessions = [...allSessions, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesSessions(response.data.next, sessions);
  } else {
    return sessions;
  }
}

export async function getGamesSessions() {
  return fetchGamesSessions(`/api/games/sessions/`);
}

async function fetchGames(url, allSessions = []) {
  const response = await axiosClient.get(url);
  const sessions = [...allSessions, ...response.data.results];
  
  if (response.data.next) {
    return fetchGames(response.data.next, sessions);
  } else {
    return sessions;
  }
}

export async function getGames() {
  return fetchGames(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function fetchScenarioLevelsPage(url, allScenarioLevels = []) {
  const response = await axiosClient.get(url);
  const scenarioLevels = [...allScenarioLevels, ...response.data.results];
  
  if (response.data.next) {
    return fetchScenarioLevelsPage(response.data.next, scenarioLevels);
  } else {
    return scenarioLevels;
  }
}

async function getScenarioLevels(scenarioId) {  
  return scenarioId ? fetchScenarioLevelsPage(`/api/games/scenario-levels/?scenarios=${scenarioId}`) : [];
}

export const scenarioLevelsQuery = (scenarioId) => {
  return {
    queryKey: ['scenarioLevels', scenarioId],
    queryFn: async () => getScenarioLevels(scenarioId),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function fetchDecisionsPage(url, allDecisions = []) {
  const response = await axiosClient.get(url);
  const decisions = [...allDecisions, ...response.data.results];
  
  if (response.data.next) {
    return fetchDecisionsPage(response.data.next, decisions);
  } else {
    return decisions;
  }
}

export async function getDecisions({userId, gameId}) {
  return fetchDecisionsPage(`/api/classification/decisions/?user=${userId}&game=${gameId}`);
}


async function fetchTransactionDecisionsPage(url, allTransactionDecisions = []) {
  const response = await axiosClient.get(url);
  const transactionDecisions = [...allTransactionDecisions, ...response.data.results];
  
  if (response.data.next) {
    return fetchTransactionDecisionsPage(response.data.next, transactionDecisions);
  } else {
    return transactionDecisions;
  }
}

export async function getTransactionDecisions({userId, gameId}) {
  return fetchTransactionDecisionsPage(`/api/profit/decisions/?user=${userId}&game=${gameId}`);
}

async function fetchEqueationDecisionsPage(url, allTransactionDecisions = []) {
  const response = await axiosClient.get(url);
  const transactionDecisions = [...allTransactionDecisions, ...response.data.results];
  
  if (response.data.next) {
    return fetchEqueationDecisionsPage(response.data.next, transactionDecisions);
  } else {
    return transactionDecisions;
  }
}

export async function getEquationDecisions({userId, gameId}) {
  return fetchEqueationDecisionsPage(`/api/equation/decisions/?user=${userId}&game=${gameId}`);
}

async function fetchNormalDecisionsPage(url, allTransactionDecisions = []) {
  const response = await axiosClient.get(url);
  const transactionDecisions = [...allTransactionDecisions, ...response.data.results];
  
  if (response.data.next) {
    return fetchNormalDecisionsPage(response.data.next, transactionDecisions);
  } else {
    return transactionDecisions;
  }
}

export async function getNormalDecisions({userId, gameId}) {
  return fetchNormalDecisionsPage(`/api/normal/decisions/?user=${userId}&game=${gameId}`);
}

export async function getCourseMembers() {
  return fetchPageData(`${BASE_URL}/api/users/course-members/`);
}

export async function updateCourseMemberViewWelcome({id}) {
  const { data } = await axiosClient.patch(`/api/users/course-members/${id}/`, {
    view_welcome: true
  });

  return data;
}