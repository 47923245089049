import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';
import Explanation from '../UI/introGameLayout/explanation';

import BackgroundImage from './images/game-background-blurred.jpg';

import {
  gamesQuery,
  getGamesSessions,
  createGameSession, 
  updateGameSession
} from './api';

import { 
  topOptions,
  changeTypeOptions,
  coreTypeOptions,
  transactionTypeOptions,
  tutorialQuestions,
  tutorialCorrectDecisions
} from './data';

import Modal from '../UI/modal';
import BalanceSheet from '../UI/balancedEquation/balanceSheet';

import TransactionRecords from '../UI/balancedEquation/transactionRecords';

import './styles.css';

export default function BalancedEquationTutorial() {
  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const { getUser } = useAuth();

  const user = getUser();

  const [currentGameId, setCurrentGameId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Balanced Equation'));

      if(game) {
        setCurrentGameId(game.id);
      }
    }
  }, [games]);

  const [questions, setQuestions] = useState(tutorialQuestions);
  
  const [activeQuestionId, setActiveQuestionId] = useState(tutorialQuestions[0].id);
  const [questionAnsweredCorrectly, setQuestionAnsweredCorrectly] = useState(0);

  const [isPlaying, setIsPlaying] = useState(levelNumber === 1 ? false : true);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [assets, setAssets] = useState(0);
  const [liabilities, setLiabilities] = useState(0);
  const [equity, setEquity] = useState(0);

  const [assetsChildren, setAssetsChildren] = useState([]);
  const [liabilitiesChildren, setLiabilitiesChildren] = useState([]);
  const [equityChildren, setEquityChildren] = useState([]);

  const assetsChildrenRef = useRef(assetsChildren);
  const liabilitiesChildrenRef = useRef(liabilitiesChildren);
  const equityChildrenRef = useRef(equityChildren);

  const [time, setTime] = useState(0);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (!isPlaying) return;
    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying]);

  const [answerStatus, setAnswerStatus] = useState(null);
  const [isExplanationVisible, setIsExplanationVisible] = useState(false);
  const [explanationContent, setExplanationContent] = useState(null);

  const [timeFromAppear, setTimeFromAppear] = useState(0);

  const [isQuestionTypingCompleted, setIsQuestionTypingCompleted] = useState(false);
  const [shouldQuestionTypingStart, setShouldQuestionTypingStart] = useState(levelNumber === 1 ? false : true);

  const [isSubmitButtonShown, setIsSubmitButtonShown] = useState(true);

  const handleQuestionTypingComplete = () => {
    setTimeFromAppear(timeRef.current);
    setIsSubmitButtonShown(true);
    setIsQuestionTypingCompleted(true);

    // if(!isAllHintsShown) {
      setIsTopHintShown(true);
    // }
  }

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessionsTutorial'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  const queryClient = useQueryClient();

  const createGameSessionMutation = useMutation({
    mutationFn: (data) => createGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsTutorial']);
    },
    onError() {
      console.log('Server error');
    }
  });
  
  const updatedGameSessionMutation = useMutation({
    mutationFn: (data) => updateGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsTutorial']);
    },
    onError() {
      console.log('Server error');
    }
  });

  useEffect(() => {
    if(currentGameId) {
      if (levelNumber === 1 && gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
        navigate('/balanced-equation/level/1');
      }
    }
  }, [gamesSessions, navigate, user?.id, currentGameId]);

  const balanceSheetTotalChange = (optionName, value) => {
    if (optionName === "Assets") {
      setAssets(prevAssets => prevAssets + value);
    } else if (optionName === "Liabilities") {
      setLiabilities(prevLiabilities => prevLiabilities + value);
    } else if (optionName === "Equity") {
      setEquity(prevEquity => prevEquity + value);
    }
  }
  
  const balanceSheetChildrenChange = (optionName, item, action, canRemove = false) => {
    if (optionName === "Assets") {
      if (action === 'add') {
        const existingItemIndex = assetsChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...assetsChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setAssetsChildren(updatedChildren);
          assetsChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...assetsChildrenRef.current, item];
          setAssetsChildren(newChildren);
          assetsChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = assetsChildrenRef.current.filter(child => child.name !== item.name);
        setAssetsChildren(newChildren);
        assetsChildrenRef.current = newChildren;  // Update the ref
      }
    } else if (optionName === "Liabilities") {
      if (action === 'add') {
        const existingItemIndex = liabilitiesChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...liabilitiesChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setLiabilitiesChildren(updatedChildren);
          liabilitiesChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...liabilitiesChildrenRef.current, item];
          setLiabilitiesChildren(newChildren);
          liabilitiesChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = liabilitiesChildrenRef.current.filter(child => child.name !== item.name);
        setLiabilitiesChildren(newChildren);
        liabilitiesChildrenRef.current = newChildren;  // Update the ref
      }
    } else if (optionName === "Equity") {
      if (action === 'add') {
        const existingItemIndex = equityChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...equityChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setEquityChildren(updatedChildren);
          equityChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...equityChildrenRef.current, item];
          setEquityChildren(newChildren);
          equityChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = equityChildrenRef.current.filter(child => child.name !== item.name);
        setEquityChildren(newChildren);
        equityChildrenRef.current = newChildren;  // Update the ref
      }
    }
  };

  const [filledRecords, setFilledRecords] = useState([]);
  
  const [isTopHintShown, setIsTopHintShown] = useState(false);
  const [isChangeHintShown, setIsChangeHintShown] = useState(false);
  const [changeHintContent, setChangeHintContent] = useState(levelNumber === 1 ? <span>Assuming the above transaction increase Assets, let’s drag and drop the “+” sign to assets</span> : <span><b>Decision 1:</b> decide if the transaction increase and/or decrease the Balance Sheet Elements.</span>);
  const [isCoreHintShown, setIsCoreHintShown] = useState(false);
  const [isTransactionHintShown, setIsTransactionHintShown] = useState(false);
  const [isSubmitHintShown, setIsSubmitHintShown] = useState(false);
  const [wasSubmitHintShow, setWasSubmitHintShow] = useState(false);

  const optionsToBeFilled = levelNumber <=3 ? levelNumber + 1 : 4;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const matchingRecords = filledRecords.filter(record => record.droppedItemsCount === optionsToBeFilled);
    const recordsWithDroppedItems = filledRecords.filter(record => record.droppedItemsCount > 0);

    if (
      matchingRecords.length !== 2 
        || recordsWithDroppedItems.length !== 2
        || !isQuestionTypingCompleted
        || (
          matchingRecords[0].optionAccountItemId === matchingRecords[1].optionAccountItemId
            && (
              matchingRecords[0].changeId === matchingRecords[1].changeId
                || (matchingRecords[0].coreId && matchingRecords[1].coreId && (matchingRecords[0].coreId === matchingRecords[1].coreId))
                || (matchingRecords[0].transactionId && matchingRecords[1].transactionId && (matchingRecords[0].transactionId === matchingRecords[1].transactionId))
            )
      )
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
      if(!wasSubmitHintShow) {
        const submitHintContent = levelNumber === 1 
          ? <span>Great! You can now submit because you have two records with a decision in each.</span>
          : <span>Great! You can now submit because you have two records with {levelNumber} types of decisions in each.</span>;
        
        setSubmitButtonHintContent(submitHintContent);
        setIsSubmitHintShown(true);

        setWasSubmitHintShow(true);
      }
    }
  }, [filledRecords, optionsToBeFilled, isQuestionTypingCompleted, levelNumber, wasSubmitHintShow]);

  const onDropAreaChange = ({
    id,
    droppedItemsCount,
    box,
    optionAccountItemId,
    optionName,
    changeId,
    changeAccountItemId,
    changeDescription,
    coreId,
    coreName,
    transactionId,
    transactionAccountItemId,
    transactionName
  }) => {
    if(levelNumber === 1 && !firstDropWasMade) {
      setIsChangeHintShown(true);
      setChangeHintContent(<span>Excellent! To make this equation balance, we’re going to assume that the transaction also increases Equity. Let’s drag and drop the “+” sign to Equity</span>);
      setFirstDropWasMade(true);
    }
    if(shouldBalanceSheetSlideIn) {
      setShouldBalanceSheetSlideIn(false);
    }
    setFilledRecords(prevRecords => {
      const recordExists = prevRecords.some(record => record.id === id);
      let updatedRecords;
  
      if(recordExists) {
        updatedRecords = prevRecords.map(record => {
          if (record.id === id) {
            const updatedRecord = { ...record, droppedItemsCount };
            if(changeId) {
              updatedRecord.changeId = changeId;
              updatedRecord.changeAccountItemId = changeAccountItemId;
              updatedRecord.changeDescription = changeDescription;
            } else if(changeId === 0) {
              updatedRecord.changeId = null;
              updatedRecord.changeAccountItemId = null;
              updatedRecord.changeDescription = null;
            }
            if(coreId) {
              updatedRecord.coreId = coreId;
              updatedRecord.coreName = coreName;
            } else if(coreId === 0) {
              updatedRecord.coreId = null;
              updatedRecord.coreName = null;
            }
            if(transactionId){
              updatedRecord.transactionId = transactionId;
              updatedRecord.transactionAccountItemId = transactionAccountItemId;
              updatedRecord.transactionName = transactionName;
            } else if(transactionId === 0) {
              updatedRecord.transactionId = null;
              updatedRecord.transactionAccountItemId = null;
              updatedRecord.transactionName = null;
            }
            return updatedRecord;
          }
          return record;
        });
      } else {
        const recordItem = {
          id,
          droppedItemsCount,
          box,
          optionAccountItemId,
          optionName,
          changeId,
          changeAccountItemId,
          changeDescription,
          coreId,
          coreName,
          transactionId,
          transactionAccountItemId,
          transactionName
        };
        updatedRecords = [...prevRecords, recordItem];
      }
  
      return updatedRecords;
    });
  };

  const [resetKey, setResetKey] = useState(0);
  const [incorrectDecisions, setIncorrectDecisions] = useState([]);

  const handleNextButtonClick = () => {
    setResetKey(prevKey => prevKey + 1);
    setFilledRecords([]);
    setIsSubmitButtonShown(true);
    setIsExplanationVisible(false);
    setIncorrectDecisions([]);

    if(questions.length > 1) {
      setIsQuestionTypingCompleted(false);
      setActiveQuestionId(prevActiveQuestionId => {
        const currentIndex = questions.findIndex(question => question.id === prevActiveQuestionId);
        
        const nextIndex = (currentIndex === questions.length - 1) ? 0 : currentIndex + 1;
        
        return questions[nextIndex].id;
      });

      if(questionAnsweredCorrectly) {
        setQuestions(prevQuestions => prevQuestions.filter(question => question.id !== questionAnsweredCorrectly));
        setQuestionAnsweredCorrectly(0);
      }
    } else {
      if(questionAnsweredCorrectly) {
        setIsPlaying(false);
        setIsModalVisible(true);
      }
    }

    setIsItemsDisabled(false);
  }

  const handleSubmitButtonClick = () => {
    const latestFilledRecords = filledRecords.filter(filledRecord => filledRecord.droppedItemsCount);
    const activeQuestion = questions.find(question => question.id === activeQuestionId);

    const currentTutorialCorrectDecisions = levelNumber <= 3 ? tutorialCorrectDecisions[levelNumber - 1] : tutorialCorrectDecisions[2];

    const decisionResults = [];
    for (const record of latestFilledRecords) {
      if (record.changeId) {
        const isCorrect = currentTutorialCorrectDecisions?.some(decision =>
          decision.type === 'change' &&
          decision.itemOption === record.optionAccountItemId &&
          decision.selectedOption === record.changeId
        );
        
        decisionResults.push({
          id: record.changeId,
          isCorrect: isCorrect,
          type: 'change',
          description: record.changeDescription,
          optionAccountItemId: record.optionAccountItemId,
          optionName: record.optionName,
          isSecondDropArea: record.box === 'Bottom',
          questionId: activeQuestion.id,
          name: record.changeDescription,
          value: activeQuestion.value
        });
      }

      if (record.coreId) {
        const isCorrect = currentTutorialCorrectDecisions.some(decision =>
          decision.type === 'core' &&
          decision.itemOption === record.optionAccountItemId &&
          decision.selectedChange === record.changeAccountItemId &&
          decision.selectedOption === record.coreId
        );

        decisionResults.push({
          id: record.coreId,
          isCorrect: isCorrect,
          type: 'core',
          optionAccountItemId: record.optionAccountItemId,
          optionName: record.optionName,
          isSecondDropArea: record.box === 'Bottom',
          questionId: activeQuestion.id,
          name: record.coreName,
          value: activeQuestion.value
        });
      }

      if (record.transactionId) {
        const isCorrect = currentTutorialCorrectDecisions.some(decision =>
          decision.type === 'transaction' &&
          decision.itemOption === record.optionAccountItemId &&
          decision.selectedOption === record.transactionId
        );
        
        decisionResults.push({
          id: record.transactionId,
          isCorrect: isCorrect,
          type: 'transaction',
          optionAccountItemId: record.optionAccountItemId,
          optionName: record.optionName,
          isSecondDropArea: record.box === 'Bottom',
          questionId: activeQuestion.id,
          name: record.transactionName,
          value: activeQuestion.value
        });
      }
    }
   
    const correctResults = decisionResults.filter(decisionResult => decisionResult.isCorrect);

    const allResultsCorrect = correctResults.length === decisionResults.length;

    if (allResultsCorrect) {        
      setQuestionAnsweredCorrectly(decisionResults[0].questionId);
      setAnswerStatus('correct');

      let firstUpdatedOptionName = '';
      switch(latestFilledRecords[0].optionName) {
        case 'Assets':
          firstUpdatedOptionName = 'an "Asset"';
          break;
        case 'Liabilities':
          firstUpdatedOptionName = 'a "Liability"';
          break;
        case 'Equity':
          firstUpdatedOptionName = 'an "Equity"';
          break;
        default:
          firstUpdatedOptionName = 'an "Asset"';
          break;
      }
      
      let secondUpdatedOptionName = '';
      switch(latestFilledRecords[1].optionName) {
        case 'Assets':
          secondUpdatedOptionName = 'an "Asset"';
          break;
        case 'Liabilities':
          secondUpdatedOptionName = 'a "Liability"';
          break;
        case 'Equity':
          secondUpdatedOptionName = 'an "Equity"';
          break;
        default:
          secondUpdatedOptionName = 'an "Asset"';
          break;
      }

      let explanation = '';
      switch(decisionResults.length) {
        case 2:
          explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
          break;
        case 4:
          explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} account, "${latestFilledRecords[0].coreName}", should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} account, "${latestFilledRecords[1].coreName}", should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
          break;
        case 6:
          explanation = `"${latestFilledRecords[0].coreName}" should be "${latestFilledRecords[0].transactionName}ed" since it’s ${firstUpdatedOptionName} that "${latestFilledRecords[0].changeDescription}d". "${latestFilledRecords[1].coreName}" should be "${latestFilledRecords[1].transactionName}ed" since it’s ${secondUpdatedOptionName} that "${latestFilledRecords[1].changeDescription}d". Click "Next" to continue.`;
          break;
        default:
          explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
          break;
      }
      setExplanationContent(explanation);
      setIsExplanationVisible(true);
    } else {
      const currentIncorrectDecisions = decisionResults.filter(decisionResult => !decisionResult.isCorrect);
      setIncorrectDecisions(currentIncorrectDecisions);

      let coreAdjustmentValue;
      decisionResults.forEach(decision => {
        if (decision.type === 'change') {
          const lowerCaseOptionName = decision.optionName.toLowerCase();
          const adjustmentValue = decision.description === 'increase' ? -activeQuestion.value : activeQuestion.value;
          coreAdjustmentValue = adjustmentValue;
    
          switch (lowerCaseOptionName) {
            case 'assets':
              setAssets(prevAssets => prevAssets + adjustmentValue);
              break;
            case 'liabilities':
              setLiabilities(prevLiabilities => prevLiabilities + adjustmentValue);
              break;
            case 'equity':
              setEquity(prevEquity => prevEquity + adjustmentValue);
              break;
            default:
              console.error(`Unknown option name: ${lowerCaseOptionName}`);
          }
        } else if (decision.type === 'core') {
          balanceSheetChildrenChange(decision.optionName, {name: decision.name, value: coreAdjustmentValue}, 'add', true);
        }
      });

      setAnswerStatus('incorrect');
      setExplanationContent('Some or all of your decisions are not correct. Don’t worry, you’ll have another chance to answer this question. Click "Next" to continue.');
      setIsExplanationVisible(true);
    }

    setIsSubmitButtonShown(false);
    setIsItemsDisabled(true);
  };

  const activeQuestion = questions.find(question => question.id === activeQuestionId);

  const [isExplanationCenterVisible, setIsExplanationCenterVisible] = useState(levelNumber === 1 ? true : false);
  const [shouldBalanceSheetSlideIn, setShouldBalanceSheetSlideIn] = useState(levelNumber === 1 ? true : false);
  const [isFooterVisible, setIsFooterVisible] = useState(levelNumber > 1 ? true : false);

  useEffect(() => {
    if(isExplanationCenterVisible && levelNumber === 1) {
      const timer = setTimeout(() => {
        setIsExplanationCenterVisible(false);
        setIsFooterVisible(true);
        setShouldQuestionTypingStart(true);
        setIsPlaying(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isExplanationCenterVisible, levelNumber]);

  const handleReadyButtonClick = () => {
    if(levelNumber === 1) {
      if(gamesSessions.length) {
        const currentSession = gamesSessions.find(session => session.user === user?.id && session.game === currentGameId);
        if(currentSession) {
          updatedGameSessionMutation.mutate({ id: currentSession.id });
        } else {
          createGameSessionMutation.mutate({ game: currentGameId, user: user?.id });
        }
      } else {
        createGameSessionMutation.mutate({ game: currentGameId, user: user?.id });
      }
    } else {
      navigate(`/balanced-equation/level/${levelNumber}`);
    }
  }


  // const [isAllHintsShown, setIsAllHintsShown] = useState(false);
  
  const topHintContent = levelNumber === 1 
    ? <span>In this level, you are deciding how each transaction increase and/or decrease Balance Sheet Elements (Assets, Liabilities, Equity).</span>
    : <span>In this level, you are making {levelNumber} types of decisions.</span>;

  const handleTopHintButtonClick = () => {
    setIsTopHintShown(false);
    setIsChangeHintShown(true);
  }

  const handleChangeHintButtonClick = () => {
    setIsChangeHintShown(false);
    
    if(levelNumber > 1) {
      setIsCoreHintShown(true);
    } else {
    }
  }

  const handleCoreHintButtonClick = () => {
    setIsCoreHintShown(false);

    if(levelNumber > 2) {
      setIsTransactionHintShown(true);
    } else {
    }
  }

  const handleTransactionHintButtonClick = () => {
    setIsTransactionHintShown(false);
  }

  const handleSubmitHintButtonClick = () => {
    setIsSubmitHintShown(false);
  }

  const [firstDropWasMade, setFirstDropWasMade] = useState(false);

  const [isItemsDisabled, setIsItemsDisabled] = useState(false);

  const [isSubmitHoverHintShown, setIsSubmitHoverHintShown] = useState(false);
  const [submitButtonHintContent, setSubmitButtonHintContent] = useState(<span></span>);

  const handleSubmitButtonMouseEnter = () => {
    if(isSubmitButtonShown && isButtonDisabled && isQuestionTypingCompleted) {
      let submitMessage = '';

      const recordsWithDroppedItems = filledRecords.filter(record => record.droppedItemsCount > 0);

      if(recordsWithDroppedItems.length < 2) {
        submitMessage = 'To submit, there must be decisions in 2 records.';
      } else if(recordsWithDroppedItems.length === 2) {
        const bothHaveChangeId = recordsWithDroppedItems.every(record => record.changeId);
        if(!bothHaveChangeId) {
          submitMessage = 'Increase or decrease needs to be in 2 records.';
        } else {
          const bothHaveCoreId = recordsWithDroppedItems.every(record => record.coreId);
          if(!bothHaveCoreId && levelNumber >= 2) {
            submitMessage = 'An account needs to be in 2 records.';
          } else {
            const bothHaveTransactionId = recordsWithDroppedItems.every(record => record.transactionId);
            if(!bothHaveTransactionId  && levelNumber >= 3) {
              submitMessage = 'A debit or a credit needs to be in 2 records.';
            } else {
              if(recordsWithDroppedItems[0].changeId === recordsWithDroppedItems[1].changeId) {
                submitMessage = 'An increase or decrease decision is missing.';
              } else if(recordsWithDroppedItems[0].coreId === recordsWithDroppedItems[1].coreId) {
                submitMessage = 'An account decision is missing.';
              } else if(recordsWithDroppedItems[0].transactionId === recordsWithDroppedItems[1].transactionId) {
                submitMessage = 'A debit or credit decision is missing.';
              }
            }
          }
        }
      } else {
        submitMessage = 'There are too many records; decisions should be in no more than 2 records.';
      }

      setSubmitButtonHintContent(submitMessage);
      setIsSubmitHoverHintShown(true);
    }
  }

  const handleSubmitButtonMouseLeave = () => {
    setIsSubmitHoverHintShown(false);
  }

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isHeaderVisible={true}
      isFooterVisible={isFooterVisible}
      time={time}
      totalScore={0}
      scoreLabel="Points"
      isExplanationVisible={isExplanationVisible}
      explanationName="Bailey"
      isAvatarVisible={true}
      answerStatus={answerStatus}
      explanationContent={explanationContent}
    >
      {
        (isTopHintShown || isChangeHintShown || isCoreHintShown || isTransactionHintShown || isSubmitHintShown) &&
          <div className='background-overlay'/>
      }
      {
        isExplanationCenterVisible &&
          <Explanation 
            isAvatarVisible={true}
            name="Bailey"
            content="Before we get started, let’s walk through a business transaction to better prepare you for this exercise."
          />
      }
      {
        !isExplanationCenterVisible &&
        <>
          <TransactionRecords 
            transactionText={activeQuestion?.description}
            shouldTypingStart={shouldQuestionTypingStart}
            onTypingComplete={handleQuestionTypingComplete}
            value={activeQuestion?.value}
            topOptions={topOptions}
            changeTypeOptions={changeTypeOptions}
            coreTypeOptions={levelNumber > 1 ? coreTypeOptions : []}
            transactionTypeOptions={levelNumber > 2 ? transactionTypeOptions : []}
            onChangeItemDrop={balanceSheetTotalChange}
            onCoreItemDrop={balanceSheetChildrenChange}
            onDropAreaChange={onDropAreaChange}
            isSubmitButtonShown={isSubmitButtonShown}
            onSubmitButtonClick={handleSubmitButtonClick}
            onSubmitButtonMouseEnter={handleSubmitButtonMouseEnter}
            onSubmitButtonMouseLeave={handleSubmitButtonMouseLeave}
            onNextButtonClick={handleNextButtonClick}
            isButtonDisabled={isButtonDisabled}
            resetKey={resetKey}
            incorrectDecisions={incorrectDecisions}
            isTopHintShown={isTopHintShown}
            topHintContent={topHintContent}
            onTopHintButtonClick={handleTopHintButtonClick}
            isChangeHintShown={isChangeHintShown}
            isCoreHintShown={isCoreHintShown}
            isTransactionHintShown={isTransactionHintShown}
            changeHintContent={changeHintContent}
            onChangeHintButtonClick={handleChangeHintButtonClick}
            onCoreHintButtonClick={handleCoreHintButtonClick}
            onTransactionHintButtonClick={handleTransactionHintButtonClick}
            isSubmitHintShown={isSubmitHintShown || isSubmitHoverHintShown}
            submitHintContent={submitButtonHintContent}
            onSubmitHintButtonClick={!isSubmitHoverHintShown ? handleSubmitHintButtonClick : undefined}
            isItemsDisabled={isItemsDisabled}
          />
            <BalanceSheet
              assets={assets}
              liabilities={liabilities}
              equity={equity}
              assetsChildren={assetsChildren}
              liabilitiesChildren={liabilitiesChildren}
              equityChildren={equityChildren}
              shouldSlideIn={shouldBalanceSheetSlideIn}
            />
          {isModalVisible && (
            <Modal
              content={ 
                <>
                  <div className="modal-content align-center">
                    <p>Awesome!</p>
                    <p>You’ve completed the tutorial!</p>
                    <p>Ready to play? </p>
                  </div>
                  <div className="next-button" onClick={handleReadyButtonClick}>I’m Ready!</div>
                </>
              }
            />
          )}
        </>
      }
    </Layout>
  );
}
