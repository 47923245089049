import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

import './styles.css';
import Hint from '../../hint';

export default function TransactionRecordsHeader({
  transactionText = '',
  shouldTypingStart,
  onTypingComplete,
  ishintShown,
  hintContent,
  onHintButtonClick
}) {
  const typedRef = useRef(null);

  useEffect(() => {
    if (transactionText.trim() === '' || !shouldTypingStart) {
      return;
    }

    const options = {
      strings: [transactionText],
      typeSpeed: 20,
      smartBackspace: true,
      loop: false,
      showCursor: false,
      onComplete: () => {
        if (onTypingComplete) {
          onTypingComplete();
        }
      },
    };

    const typed = new Typed(typedRef.current, options);

    return () => {
      // Destroy Typed.js instance on cleanup
      typed.destroy();
    };
  }, [transactionText, shouldTypingStart]);

  return (
    <div className='transaction-records-header'>
      <h2>Record the following transaction:</h2>
      <p><span ref={typedRef}></span></p>
      
      {
        ishintShown &&
          <Hint 
            content={hintContent}
            buttonText="Next"
            onButtonClick={onHintButtonClick}
          />
      }
    </div>
  );
}
