import AccountClassificationImage from '../../../images/game-account-classification.png';
import NormalBalanceImage from '../../../images/game-normal-balance.png';
import ProfitImage from '../../../images/game-the-profit.png';
import BalancedEquationImage from '../../../images/game-the-balanced-equation.png';
import AccountingCycleGameImage from '../../../images/game-accounting-cycle-game.png';
import IntroToBusinessImage from '../../../images/game-the-intro-to-business.png';

export const gameTemplates = [
  {
    type: 'Account Classification',
    color: '#FDD0D4',
    image: AccountClassificationImage
  },
  {
    type: 'Normal Balance | (debits & credits)',
    color: 'rgba(30, 144, 255, 0.5)',
    image: NormalBalanceImage
  },
  {
    type: 'The Profit | (principles and more)',
    color: '#F7DAA3',
    image: ProfitImage
  },
  {
    type: 'The Balanced Equation | (debits & credits)',
    color: '#F1C265',
    image: BalancedEquationImage
  },
  {
    type: 'Accounting Cycle Game',
    color: '#91D5DA',
    image: AccountingCycleGameImage
  },
  {
    type: 'The Intro to Business Game',
    color: '#65B8C0',
    image: IntroToBusinessImage
  }
];