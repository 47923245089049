import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import { calculateTimeInSeconds } from '../../utils';
import {
  pixelsPerFall,
  blockHeight,
  blockMaxWidth,
  fallingAreaBottomGap
} from '../../vars';

import Intro from '../UI/game/intro';
import Header from '../UI/game/header';
import Modal from '../UI/modal';
import FallingBlock from '../UI/game/falling-block';
import Column from '../UI/game/column';
import FooterMessage from '../UI/game/footer-message';
import Hint from '../UI/hint';

import { 
  gamesQuery,
  scenarioLevelsQuery,
  itemOptionsQuery,
  itemQuestionsQuery,
  itemsQuery,
  makeDecision,
  getDecisions,
  getGamesSessions
} from './api';

import './styles.css';

import CountdownSound from './sounds/countdown.mp3';
import GameProcessSound from './sounds/game-process.mp3';
import ClickSound from './sounds/click.mp3';
import ItemHitSpikeSound from './sounds/item-hit-spike.mp3';
import AnswerCorrectSound from './sounds/answer-correct.mp3';
import AnswerWrongSound from './sounds/answer-wrong.mp3';

export default function NormalBalance() {
  const countdownEffect = useRef(new Audio(CountdownSound));
  const gameProcessEffect = useRef(new Audio(GameProcessSound));
  const clickEffect = useRef(new Audio(ClickSound));
  const itemHitSpikeEffect = useRef(new Audio(ItemHitSpikeSound));
  const answerCorrectEffect = useRef(new Audio(AnswerCorrectSound));
  const answerWrongEffect = useRef(new Audio(AnswerWrongSound));

  const navigate = useNavigate();
  const location = useLocation();

  /* Prevent sound from playing on other pages */
  useEffect(() => {
    const currentGameProcessEffect = gameProcessEffect.current;
  
    return () => {
      if (currentGameProcessEffect) {
        currentGameProcessEffect.pause();
      }
    };
  }, [location]);
  

  const params = useParams();
  const levelNumber = Number(params.levelNumber);
  
  const { getUser, getGuidedPlay } = useAuth();
  const user = getUser();

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const { soundStatus } = useSoundStatus();

  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);
  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Normal Balance'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessionsLevels'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if(currentGameId) {
      if (gamesSessions && !gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
        let redirectUrl;
        if(shouldGuidedPagesBeDisplayed) {
          redirectUrl = '/normal/level/1/intro';
        } else {
          redirectUrl = '/normal/tutorial';
        }
        navigate(redirectUrl);
      }
    }
  }, [gamesSessions, navigate, user?.id, currentGameId, shouldGuidedPagesBeDisplayed]);

  const fallingAreaRef = useRef(null);

  const [scenarioLevel, setScenarioLevel] = useState({
    description: '',
    fallingSpeed: 1,
    enabled: false
  });

  const [isPlaying, setIsPlaying] = useState(false);

  const [fallingBlocks, setFallingBlocks] = useState([]);
  const [availableBlockItems, setAvailableBlockItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(0);
  const [prePlacedItems, setPrePlacedItems] = useState({});
  const availableBlockItemsRef = useRef(availableBlockItems);

  const scenarioLevelsResponse = useQuery(scenarioLevelsQuery({scenarioId})) || {};

  const itemOptionsResponse = useQuery(itemOptionsQuery()) || {};
  
  const itemQuestionsResponse = useQuery(itemQuestionsQuery({scenarioLevelId: scenarioLevel.id})) || {};

  const itemsResponse = useQuery(itemsQuery()) || {};

  // const decisionsResponse = useQuery(decisionsQuery(levelNumber, user?.id)) || {};
  const { data: decisionsResponse, isSuccess: isDecisionsSuccess } = useQuery({
    queryKey: ['decisionsResponse', { levelNumber, userId: user?.id, gameId: currentGameId }],
    queryFn: () => getDecisions({ levelNumber, userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && levelNumber !== undefined,
    refetchOnWindowFocus: false
  });
  
  useEffect(() => {
    const scenarioLevels = scenarioLevelsResponse.data || [];
    if(scenarioLevels.length) {
      const scenarioLevel = scenarioLevels.find(scenarioLevel => scenarioLevel.level === levelNumber);
      setScenarioLevel({
        id: scenarioLevel.id,
        description: scenarioLevel.description,
        fallingSpeed: scenarioLevel.falling_speed * 1000,
        duration: calculateTimeInSeconds(scenarioLevel.duration)
      });
    }
  }, [levelNumber, scenarioLevelsResponse.data]);

  const [options, setOptions] = useState([]);

  const [totalScore, setTotalScore] = useState(0);
  const [shouldScorePlaySound, setShouldScorePlaySound] = useState(false);

  useEffect(() => {
    const itemOptions = itemOptionsResponse.data || [];
    const itemQuestions = itemQuestionsResponse.data || [];
    const items = itemsResponse.data || [];
    
    if(
      itemOptions.length &&
      itemQuestions.length &&
      items.length &&
      isDecisionsSuccess
    ) {
      const decisions = decisionsResponse || [];
      
      const scenarioLevelItemOptions = itemOptions.filter(itemOption => itemOption.scenario_level === scenarioLevel.id);

      const createItemObject = (item, children = []) => {
        const scenarioLevelItemOption = itemOptions.find(scenarioLevelItemOption => {
          if (item && 'id' in item) {
            return scenarioLevelItemOption.account_item === item.id;
          }
          return false;
        });
        
        const hierarchicalItem = {
          id: scenarioLevelItemOption && scenarioLevelItemOption.id,
          order: scenarioLevelItemOption && scenarioLevelItemOption.order,
          name: item && item.name,
          description: item && item.description,
          children: Array.isArray(children) ? children : [children],
          itemId: item && item.id
        };
      
        if (item && item.parents.length) {
          let parentItemIdWithOption = item.parents.find(parentId => {
            return scenarioLevelItemOptions.some(option => option.account_item === parentId);
          });

          if(!parentItemIdWithOption) {
            parentItemIdWithOption = item.parents.find(parentId => {
              return itemOptions.some(option => option.account_item === parentId);
            });
          }
      
          if (parentItemIdWithOption) {
            const parentItem = items.find(possibleParentItem => possibleParentItem.id === parentItemIdWithOption);
            if (parentItem) {
              return createItemObject(parentItem, [hierarchicalItem]);
            }
          } else {
            return hierarchicalItem;
          }
        } else {
          return hierarchicalItem;
        }
      }

      const hierarchicalOptions = scenarioLevelItemOptions.map(scenarioLevelItemOption => {
        const relatedItem = items.find(item => item.id === scenarioLevelItemOption.account_item);
        return createItemObject(relatedItem);
      });
      
      function mergeAndConcatenateDuplicates(items) {
        const uniqueItemsMap = new Map();
      
        items.forEach(item => {
          if (!uniqueItemsMap.has(item.id)) {
            uniqueItemsMap.set(item.id, { ...item, children: item.children ? [...item.children] : [] });
          } else {
            const existingItem = uniqueItemsMap.get(item.id);
            if (item.children && item.children.length > 0) {
              existingItem.children = existingItem.children.concat(item.children);
            }
          }
        });
      
        uniqueItemsMap.forEach((value, key) => {
          if (value.children && value.children.length > 0) {
            value.children = mergeAndConcatenateDuplicates(value.children);
          }
        });
      
        return Array.from(uniqueItemsMap.values());
      }      

      function sortOptionsDataByOrder(data) {
        const sortedData = data.sort((a, b) => a.order - b.order);
      
        sortedData.forEach(item => {
          if (item.children && item.children.length > 0) {
            item.children = sortOptionsDataByOrder(item.children);
          }
        });
      
        return sortedData;
      }
      
      const optionsChildrenMerged = mergeAndConcatenateDuplicates(hierarchicalOptions);
      const sortedOptionsData = sortOptionsDataByOrder(optionsChildrenMerged);
      setOptions([...sortedOptionsData]);

      const questionsData = itemQuestions.map(itemQuestion => {
        const relatedItem = items.find(item => item.id === itemQuestion.account_item);
        return {
          id: itemQuestion.id,
          order: itemQuestion.order,
          name: relatedItem && relatedItem.name,
          description: relatedItem && relatedItem.description,
          parents: relatedItem && relatedItem.parents,
          accountItemId: relatedItem.id
          // hasAnimated: true
        }
      });
      
      const correctDecisions = decisions.filter(decision => decision.is_correct === true);
      
      const questionIdsToRemove = new Set(correctDecisions.flatMap(decision => decision.question));
      const [questionsToFall, questionsToBePlaced] = questionsData.reduce((result, question) => {
        if (questionIdsToRemove.has(question.id)) {
          result[1].push(question);
        } else {
          result[0].push(question);
        }
        return result;
      }, [[], []]);
      
      const findDeepestParentId = (itemParents, options, currentDepth = 0, maxDepthInfo = { depth: 0, id: null }) => {
        options.forEach(option => {
          if (itemParents.includes(option.itemId)) {
            if (currentDepth >= maxDepthInfo.depth) { // Use >= to include top-level elements
              maxDepthInfo.depth = currentDepth;
              maxDepthInfo.id = option.id;
            }
          }
          if (option.children && option.children.length > 0) {
            findDeepestParentId(itemParents, option.children, currentDepth + 1, maxDepthInfo);
          }
        });
        return maxDepthInfo.id;
      };

      const prePlacedItemsByDropArea = questionsToBePlaced.reduce((acc, item) => {
        const deepestDropAreaId = findDeepestParentId(item.parents, sortedOptionsData);
        if (deepestDropAreaId) {
          acc[deepestDropAreaId] = acc[deepestDropAreaId] || [];
          acc[deepestDropAreaId].push(item);
        }
        return acc;
      }, {});
      setPrePlacedItems({...prePlacedItemsByDropArea});

      // const allQuestionsHaveOrder = questionsData.every(item => item.hasOwnProperty('order'));
      let sortedOrRandomQuestionsData = questionsToFall;
      
      // if (allQuestionsHaveOrder) {
      //   sortedOrRandomQuestionsData = [...questionsToFall].sort((a, b) => a.order - b.order);
      // }
      setAvailableBlockItems(sortedOrRandomQuestionsData);
      if(!sortedOrRandomQuestionsData.length && correctDecisions.length) {
        setIsPlaying(true);
      }
      

      // allQuestionsHaveOrderRef.current = allQuestionsHaveOrder;

      const [totalElapsedTime, totalPointsScored] = correctDecisions.reduce(([totalTime, totalPoints], decision) => {
        let timeInSeconds = 0;
        if (decision.time_elapsed) {
          timeInSeconds = calculateTimeInSeconds(decision.time_elapsed);
        }

        totalTime += timeInSeconds;
        totalPoints += decision.score_points || 0;

        return [totalTime, totalPoints];
      }, [0, 0]);

      setTime(totalElapsedTime);
      setTotalScore(totalPointsScored);
    }
  }, [itemOptionsResponse.data, itemQuestionsResponse.data, itemsResponse.data, scenarioLevel.id, decisionsResponse, levelNumber, user?.id, isDecisionsSuccess]);

  const decisionMutation = useMutation({
    mutationFn: (data) => makeDecision(data)
  });

  const [isFooterMessageShown, setIsFooterMessageShown] = useState(false);
  const [footerMessageType, setFooterMessageType] = useState();
  const [footerMessageDescription, setFooterMessageDescription] = useState();

  const [isHintShown, setIsHintShown] = useState(false);
  const [hintContent, setHintContent] = useState(<span></span>);

  const handleMouseEnter = (id, hoverContent) => {
    setHoveredItem(id);

    setHintContent(hoverContent);
    setIsHintShown(true);
  }

  const handleMouseLeave = () => {
    setHoveredItem(0);
    setIsHintShown(false);
  }
  
  const handleMouseDown = () => {
    setHoveredItem(0);
    setIsHintShown(false);
  }

  const showFooterMessage = (type, description) => {
    setFooterMessageType(type);
    setFooterMessageDescription(description);
    setIsFooterMessageShown(true);
  }

  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalOverlayVisible, setIsModalOverlayVisible] = useState(true);
  const [isModalSmall, setIsModalSmall] = useState(false);
  const [hasShownIntroModal, setHasShownIntroModal] = useState(false);

  const [modalContent, setModalContent] = useState(
    <div className="modal-center vertical">
      <div><span>Game start!</span></div>
      <div><span className='modal-counter'>3</span></div>
    </div>
  );

  const [time, setTime] = useState(0);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (!isPlaying) return;
    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
      /* uncomment this one when we will work with durations limitation from the server */
      // if (newTime >= scenarioLevel.duration) {
      //   setIsPlaying(false);
      //   setModalContent(<div className="modal-center">Time is up</div>);
      //   setIsModalShown(true);
      //   clearInterval(timerInterval);
      // }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying, scenarioLevel]);

  const [fallingAreaWidth, setFallingAreaWidth] = useState(0);
  const [fallingAreaHeight, setFallingAreaHeight] = useState(0);

  useEffect(() => {
    const currentFallingArea = fallingAreaRef.current;
  
    const updateFallingAreaSizes = () => {
      const width = currentFallingArea?.clientWidth || 0;
      const height = currentFallingArea?.clientHeight || 0;
      setFallingAreaWidth(width);
      setFallingAreaHeight(height);
    };
  
    updateFallingAreaSizes();
    const resizeObserver = new ResizeObserver(entries => {
      updateFallingAreaSizes();
    });
  
    if (currentFallingArea) {
      resizeObserver.observe(currentFallingArea);
    }
  
    return () => {
      if (currentFallingArea) {
        resizeObserver.unobserve(currentFallingArea);
      }
    };
  }, []);
  
  useEffect(() => {
    availableBlockItemsRef.current = availableBlockItems;
  }, [availableBlockItems]);

  // const allQuestionsHaveOrderRef = useRef();

  useEffect(() => {
    if (!isPlaying) return;
  
    const generateBlock = () => {
      if (availableBlockItemsRef.current.length === 0) {
        return;
      }
  
      let blockToGenerate;
      let blockIndex;
  
      blockIndex = Math.floor(Math.random() * availableBlockItemsRef.current.length);
      blockToGenerate = availableBlockItemsRef.current[blockIndex];
      
      const isLeftSide = Math.random() < 0.5;
      let left = 'auto';
      let right = 'auto';
  
      if (isLeftSide) {
        left = Math.random() * (fallingAreaWidth / 2 - 25) + 5;
      } else {
        right = Math.random() * (fallingAreaWidth / 2 - 25) + 5;
      }
  
      const newBlock = {
        id: blockToGenerate.id,
        name: blockToGenerate.name,
        order: blockToGenerate.order,
        parents: blockToGenerate.parents,
        description: blockToGenerate.description,
        secondsFalling: blockToGenerate.secondsFalling || 0,
        accuracyCoefficient: blockToGenerate.accuracyCoefficient,
        left: left,
        right: right,
        y: 0,
        width: blockMaxWidth,
      };
  
      setFallingBlocks(prevBlocks => [...prevBlocks, newBlock]);
      setAvailableBlockItems(prev => prev.filter((_, index) => index !== blockIndex));
    };
  
    // Immediately generate a block if fallingBlocks is empty
    if (fallingBlocks.length === 0) {
      setTimeout(generateBlock, 1000);
    }
  
    const generateBlockInterval = setInterval(generateBlock, scenarioLevel.fallingSpeed * 3);
  
    return () => {
      clearInterval(generateBlockInterval);
    };
  }, [isPlaying, fallingAreaWidth, scenarioLevel.fallingSpeed, fallingBlocks.length, availableBlockItemsRef]);
  
  const fallBlocks = () => {
    const bottomGap = blockHeight + fallingAreaBottomGap + pixelsPerFall;

    setFallingBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map(block => {
        const blockY = hoveredItem === block.id ? block.y : block.y + pixelsPerFall;
        return({
          ...block,
          y: blockY,
          secondsFalling: block.secondsFalling + 1
        })
      });

      const blocksToRemove = updatedBlocks.filter(block => block.y > fallingAreaHeight - bottomGap);

      if (blocksToRemove.length > 0) {
        if (soundStatus === 'enabled') {
          itemHitSpikeEffect.current.play().catch(e => console.log('Error playing item hit spike sound:', e));
        }
        
        const newAvailableBlocks = blocksToRemove.map(block => ({
          id: block.id,
          name: block.name,
          order: block.order,
          parents: block.parents,
          description: block.description,
          secondsFalling: block.secondsFalling,
          accuracyCoefficient: block.accuracyCoefficient
        }));

        setAvailableBlockItems(prevItems => {
          const filteredNewBlocks = newAvailableBlocks.filter(newBlock => 
            !prevItems.some(item => item.id === newBlock.id)
          );

          return [...prevItems, ...filteredNewBlocks];
        });

        return updatedBlocks.filter(block => !blocksToRemove.includes(block));
      }

      return updatedBlocks;
    });

  };

  useEffect(() => {
    if (!isPlaying) return;
  
    const fallBlocksInterval = setInterval(fallBlocks, scenarioLevel.fallingSpeed);
  
    return () => {
      clearInterval(fallBlocksInterval);
    };
  }, [isPlaying, fallingAreaHeight, scenarioLevel.fallingSpeed, hoveredItem]);

  const handleDrop = (item, column, callback) => {
    setFallingBlocks(prevBlocks => prevBlocks.filter(block => block.id !== item.id));
    const onSuccess = (result) => {
      callback(null, result);

      const footerMessagePrefix = 'The Normal Balance for ';

      const footerMessageMiddle = result.is_correct
        ? ' is a '
        : ' is NOT a ';

      const footerMessageSuffix = ' Balance.';

      const footerMessage = <p>{footerMessagePrefix}<b>{item.name}</b>{footerMessageMiddle}<b>{column.name}</b>{footerMessageSuffix}</p>;

      if(result.is_correct) {
        answerCorrectEffect.current.play().catch(e => console.log('Error playing answer correct sound:', e));

        setShouldScorePlaySound(true);
        showFooterMessage('success', footerMessage);
        setTotalScore(prevTotalScore => prevTotalScore + result.score_points);
      } else {
        answerWrongEffect.current.play().catch(e => console.log('Error playing answer wrong sound:', e));

        showFooterMessage('error', footerMessage);
        const { id, name, order, parents, description, secondsFalling } = item;
        setAvailableBlockItems(prevAvalableBlockItems => [
          ...prevAvalableBlockItems, 
          { id, name, order, parents, description, secondsFalling, accuracyCoefficient: 0.5 }
        ]);
      }
    };

    const timestamp = new Date().toISOString();
  
    decisionMutation.mutate({
      user: user?.id,
      game: currentGameId,
      question: item.id,
      selected_option: column.id,
      time_elapsed: item.secondsFalling,
      timestamp
    }, {
      onSuccess: onSuccess,
      onError: (error) => {
        console.log('Error: ', error);
      }
    });
  }
  
  useEffect(() => {
    if (fallingBlocks.length === 0 && availableBlockItems.length === 0 && isPlaying) {
      const showWellDoneTimeout = setTimeout(() => {
        setIsPlaying(false);
        setIsModalOverlayVisible(false);
        setIsModalSmall(true);
        setModalContent(
          <>
            <div className="modal-center vertical">
              <div><span>Well done!</span></div>
              <button onClick={() => navigate(`/normal/level/${levelNumber}/summary/`)} className="button-blue button-view-summary">View Summary</button>
            </div>
          </>
        );
        setIsModalShown(true);
      }, 700);
  
      return () => clearTimeout(showWellDoneTimeout);
    }
  }, [fallingBlocks, availableBlockItems, levelNumber, totalScore, isPlaying, navigate]);

  const [showIntro, setShowIntro] = useState(true);

  const handleIntroClose = () => {
    setShowIntro(false);
  
    let countdown = 3;
    let countdownInterval;
  
    const updateModalContent = () => {
      if (countdown === 0) {
        setIsModalShown(false);
        setIsPlaying(true);
        clearInterval(countdownInterval);
  
        gameProcessEffect.current.volume = 0.09;
        gameProcessEffect.current.loop = true;
        if (soundStatus === 'enabled') {
          gameProcessEffect.current.play().catch(e => console.log('Error playing game process sound:', e));
        }
      } else {
        setModalContent(
          <div className="modal-center vertical">
            <div><span>Game start!</span></div>
            <div><span>{countdown}</span></div>
          </div>
        );
        countdown--;
      }
    };
    
    if (availableBlockItemsRef.current.length && !hasShownIntroModal) {
      setIsModalShown(true);
      setHasShownIntroModal(true);
  
      countdownInterval = setInterval(updateModalContent, 1000);
  
      const playSoundTimeout = setTimeout(() => {
        if (soundStatus === 'enabled') {
          countdownEffect.current.play().catch(e => console.log('Error playing countdown sound:', e));
        }
      }, 1000);
  
      return () => {
        clearTimeout(playSoundTimeout);
        clearInterval(countdownInterval);
      };
    } else {
      return () => {
        if (countdownInterval) {
          clearInterval(countdownInterval);
        }
      };
    }
  }

  useEffect(() => {  
    if (isPlaying && soundStatus === 'enabled') {
      gameProcessEffect.current.volume = 0.09;
      gameProcessEffect.current.loop = true;
      gameProcessEffect.current.play().catch(e => console.log('Error playing game process sound:', e));
    } else {
      gameProcessEffect.current.pause();
    }
  
    return () => {
      gameProcessEffect.current.pause();
    };
  }, [isPlaying, soundStatus]);

  useEffect(() => {
    const playClickSound = () => {
      if (soundStatus === 'enabled') {
        clickEffect.current.play().catch(e => console.error('Error playing click sound:', e));
      }
    };

    document.addEventListener('click', playClickSound);

    return () => {
      document.removeEventListener('click', playClickSound);
    };
  }, [soundStatus]);

  return (
    <div className="game-level-wrapper">
      {
        showIntro && (
          <Intro
            title={`Level ${levelNumber}`}
            description={scenarioLevel.description}
            onShowingCompleted={handleIntroClose}
          />
      )}
      <Header 
        time={time}
        totalScore={totalScore}
        shouldScorePlaySound={shouldScorePlaySound}
      />
      <section className="main-section">
        <div className="container columns">
          <Column
            side='left'
            options={options[0]}
            levelNumber={levelNumber}
            handleDrop={handleDrop}
            prePlacedItems={prePlacedItems}
            gameType="Normal Balance"
          />
          <main className="content">
            <div className="falling-area-container">
              <div className="falling-area" ref={fallingAreaRef}>
                {fallingBlocks.map((block) => (
                  <FallingBlock
                    key={block.id}
                    id={block.id}
                    name={block.name}
                    order={block.order}
                    parents={block.parents}
                    description={block.description}
                    secondsFalling={block.secondsFalling}
                    accuracyCoefficient={block.accuracyCoefficient}
                    left={block.left}
                    right={block.right}
                    y={block.y}  
                    onMouseEnter={() => handleMouseEnter(block.id, block.description)}
                    onMouseLeave={handleMouseLeave}
                    onMouseDown={handleMouseDown}
                    {...(isHintShown && hoveredItem === block.id && {
                      additionalContent: (
                        <Hint
                          content={hintContent}
                        />
                      )
                    })}
                  />
                ))}
              </div>
            </div>
          </main>
          <Column
            side='right'
            options={options[1]}
            levelNumber={levelNumber}
            handleDrop={handleDrop}
            prePlacedItems={prePlacedItems}
            gameType="Normal Balance"
          />
        </div>
      </section>
      <footer className="game-footer">
        {isFooterMessageShown && (
          <FooterMessage
            type={footerMessageType}
            description={footerMessageDescription}
          />
        )}
      </footer>
      {isModalShown && (
        <Modal
          content={modalContent}
          isModalSmall={isModalSmall}
        />
      )}
    </div>
  );
}
