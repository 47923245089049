import React, { useEffect, useState, useRef } from 'react';

import { getFormattedCurrency } from '../../../../utils';

import './styles.css';
import BalanceSheetRow from './row';

export default function BalanceSheet({
  assets,
  liabilities,
  equity,
  assetsChildren,
  liabilitiesChildren,
  equityChildren,
  shouldSlideIn = false
}) {
  const [displayedAssets, setDisplayedAssets] = useState(0);
  const [displayedLiabilities, setDisplayedLiabilities] = useState(0);
  const [displayedEquity, setDisplayedEquity] = useState(0);

  const prevRevenuesRef = useRef(0);
  const prevExpensesRef = useRef(0);
  const prevEquityRef = useRef(0);

  useEffect(() => {
    updateScoreWithDelay(setDisplayedAssets, displayedAssets, assets);

    updateScoreWithDelay(setDisplayedLiabilities, displayedLiabilities, liabilities);

    updateScoreWithDelay(setDisplayedEquity, displayedEquity, equity);

    prevRevenuesRef.current = assets;
    prevExpensesRef.current = liabilities;
    prevEquityRef.current = equity;

  }, [assets, liabilities, equity]);

  const updateScoreWithDelay = (setState, currentScore, endScore) => {
    if (currentScore !== endScore) {
      const duration = 1500;
      const steps = 20;
      const stepTime = duration / steps;
      let currentStep = 0;

      const totalChange = endScore - currentScore;
      const changePerStep = totalChange / steps;

      const interval = setInterval(() => {
        currentStep++;
        const newScore = currentScore + changePerStep * currentStep;
        setState(newScore);

        if (currentStep === steps) {
          clearInterval(interval);
          setState(endScore);
        }
      }, stepTime);

      return () => clearInterval(interval);
    }
  };

  const isAssetsNegative = displayedAssets < 0;
  const isLiabilitiesNegative = displayedLiabilities < 0;
  const isEquityNegative = displayedEquity < 0;

  return (
    <div className={`balance-sheet ${shouldSlideIn ? 'should-slide-in' : ''}`}>
      <h2>Balance Sheet</h2>
      <ul className="balance-sheet-items">
        <li>
          <BalanceSheetRow
            name="Assets"
            value={getFormattedCurrency(displayedAssets)}
            isNegative={isAssetsNegative}
            hasChildren={assetsChildren.length > 0}
          />
          {
            assetsChildren.length > 0 &&
              assetsChildren.map((assetsChild, index) => (
                <BalanceSheetRow
                  key={index}
                  name={assetsChild.name}
                  value={getFormattedCurrency(assetsChild.value)}
                  isNegative={assetsChild.value < 0}
                  isChild={true}
                />
              ))
          }
        </li>
        <li>
          <BalanceSheetRow
            name="Liabilities"
            value={getFormattedCurrency(displayedLiabilities)}
            isNegative={isLiabilitiesNegative}
            hasChildren={liabilitiesChildren.length > 0}
          />
          {
            liabilitiesChildren.length > 0 &&
              liabilitiesChildren.map((liabilitiesChild, index) => (
                <BalanceSheetRow
                  key={index}
                  name={liabilitiesChild.name}
                  value={getFormattedCurrency(liabilitiesChild.value)}
                  isNegative={liabilitiesChild.value < 0}
                  isChild={true}
                />
              ))
          }
        </li>
        <li>
          <BalanceSheetRow
            name="Equity"
            value={getFormattedCurrency(displayedEquity)}
            isNegative={isEquityNegative}
            hasChildren={equityChildren.length > 0}
          />
          {
            equityChildren.length > 0 &&
              equityChildren.map((equityChild, index) => (
                <BalanceSheetRow
                  key={index}
                  name={equityChild.name}
                  value={getFormattedCurrency(equityChild.value)}
                  isNegative={equityChild.value < 0}
                  isChild={true}
                />
              ))
          }
        </li>        
      </ul>
    </div>
  );
}
