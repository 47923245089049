import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';
import Modal from '../UI/introGameLayout/modal';

import BackgroundSound from '../UI/introGameLayout/backgroundSound';
import PopupMessages from '../UI/introGameLayout/popupMessages';

import { getGames, updateCourseMemberViewWelcome, getCourseMembers } from './api';

import BackgroundIntro from './images/intro-background.jpg';
import BackgroundStars1 from './images/background-stars-1.png';
import BackgroundStars2 from './images/background-stars-2.png';

import IntroProcessSound from './sounds/intro-process.mp3';

import { popupMessagesTop, popupMessagesBottom } from './data';

import './styles.css';

export default function LoginIntro() {
  const navigate = useNavigate();

  const {
    getUser,
    isInstructor,
    isStudent
  } = useAuth();

  const { data: games } = useQuery({
    queryKey: ['games'],
    queryFn: getGames,
    refetchOnWindowFocus: false
  });

  const { data: courseMembers } = useQuery({
    queryKey: ['courseMembers'],
    queryFn: getCourseMembers,
    refetchOnWindowFocus: false
  });

  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);
  const [isBackgroundStarsVisible, setIsBackgroundStarsVisible] = useState(false);
  
  const [isTopPopupMessageVisible, setIsTopPopupMessageVisible] = useState(false);
  const [isBottomPopupMessageVisible, setIsBottomPopupMessageVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBackgroundBlurred(true);
      setIsBackgroundStarsVisible(true);
      setIsTopPopupMessageVisible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleTopMessageTyped = () => {
    setIsBottomPopupMessageVisible(true);
  }

  const user = getUser();
  const userIsInstructor = isInstructor();
  const userIsStudent = isStudent();
  
  const updateCourseMemberViewWelcomeMutation = useMutation({
    mutationFn: (data) => updateCourseMemberViewWelcome(data),
    onSuccess: () => {
      navigate('/instructor/dashboard');
    },
    onError() {
      console.log('Server error');
    }
  });

  const handleBottomMessageButtonClick = () => {
    let redirectUrl;
    
    if(userIsInstructor && courseMembers?.length) {
      const courseMember = courseMembers.find(courseMember => courseMember.user_id === user.id);
      updateCourseMemberViewWelcomeMutation.mutate({id: courseMember?.id});
    } else if(userIsStudent) {
      if (user && user.alias_name) {
        if(games?.length) {
          redirectUrl = '/games';
        }
      } else {
        redirectUrl = '/onboarding';
      }
    }

    navigate(redirectUrl);
  }

  return(
    <Layout
      isHeaderVisible={true}
      backgroundImage={BackgroundIntro}
      isBackgroundBlurred={isBackgroundBlurred}
      isContentVertical={true}
    >
      <BackgroundSound 
        sound={IntroProcessSound}
      />
      {
        isTopPopupMessageVisible && 
          <PopupMessages
            messages={popupMessagesTop}
            onTypingCompleted={handleTopMessageTyped}
          />
      }
      <Modal
        title="Welcome to Ledger Legends!"
        content={<p>The #1 accounting and advisory firm in the Universe</p>}
      />
      {
        isBottomPopupMessageVisible && 
          <PopupMessages
            messages={popupMessagesBottom}
            buttonText="Let's go"
            onButtonClick={handleBottomMessageButtonClick}
          />
      }

      <img src={BackgroundStars1} alt='Stars' className={`floating-stars floating-stars-1 ${isBackgroundStarsVisible ? 'visible' : ''}`}/>
      <img src={BackgroundStars2} alt='Stars' className={`floating-stars floating-stars-2 ${isBackgroundStarsVisible ? 'visible' : ''}`}/>
    </Layout>
  );
}
